export const styleMenu = ({backgroundColor = '#373a47', closeButton = true}) => ({
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    // background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    display: (closeButton ? 'inline-block' : 'none'),
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    // position: 'fixed',
    // height: '100vh'
    overflow: 'hidden'
  },
  bmMenu: {
    background: backgroundColor,
    // padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    overflow: 'hidden',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    // color: '#b8b7ad',
    // padding: '0.8em',
    // border: '1px solid black',
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
})