import React, { useEffect } from 'react';
import {Container, FlatListContainer} from "./styles";

import { SearchBar } from "./SearchBar";
import { OrderByBar } from './OrderByBar';
import { Vehicle } from "./Vehicle";

import FlatList from 'flatlist-react';

import { useDispatch, useSelector } from 'react-redux';
import { sortVehicles } from '~/services/store/models/vehicles';
import { colors } from '~/styles';
import { ClipLoader } from 'react-spinners';
import { EmptyContainer } from "./styles";

export const RightMenu = (props) => {
  const dispatch = useDispatch()
  const { navigation, refreshing, fetchLastPositions } = props;
  const {list: vehicles, loading, lastPositions, anchorStatus, searchingFor, orderBy} = useSelector(state => state.vehicles.vehicles)
  // console.warn('DrawerMenuRight - render - positions: ', positions);

  // const { loading, error, data } = useQuery(Schema.GET_VEHICLES;

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :(</p>;

  function filterVehicles(e,i,a){
    const lastPosition = lastPositions[e.id]
    if(
      searchingFor == '' || 
      e.description && e.description.toLowerCase().includes(searchingFor.toLowerCase()) ||
      lastPosition?.driver?.name && lastPosition.driver.name.toLowerCase().includes(searchingFor.toLowerCase()) ||
      lastPosition?.address && lastPosition.address.toLowerCase().includes(searchingFor.toLowerCase())
    ){
      return true
    }
    return false
  }

  // function sortVehicles(prop) {
  //   return function(a, b) {
  //       return a[prop] - b[prop];
  //   }
  // }

  if(loading){
    return (
      <EmptyContainer>
        <ClipLoader loading={loading} color={colors.black} size="40px" margin="4px" />
      </EmptyContainer>
    )
  }

  return (
    <Container>
      {vehicles?.length == 0 && (
        <EmptyContainer>
          <p>Nenhum veículo encontrado.</p>
          <div><a onClick={()=> dispatch.vehicles.getVehicles()}>Clique aqui</a> para tentar novamente.</div>
        </EmptyContainer>
      )}
      {vehicles?.length > 0 && (
        <>
          <SearchBar/>
          <OrderByBar/>
          <FlatListContainer>
            { vehicles
                .filter(filterVehicles)
                // .map((e,i,a) => ({...e, lastPosition: {...lastPositions[e.id], orderBy}})
                .sort(sortVehicles({lastPositions, orderBy}))
                .map((e,i,a) => (
              <Vehicle
                key={e.id}
                // navigation={navigation}
                index={(i+1)}
                vehicle={e}
                lastPosition={lastPositions[e.id]}
                anchor={anchorStatus[e.id]}
              />
            ))}
          </FlatListContainer>
        </>
      )}
        {/* <FlatList
          list={data.positions}
          renderItem={(item, idx) => (
            <Vehicle
              key={idx}
              // navigation={navigation}
              item={item}
            />
          )}
          renderWhenEmpty={() => <div>List is empty!</div>}
          // sortBy={["firstName", {key: "lastName", descending: true}]}
          // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
        /> */}
        {/* <FlatList
          onRefresh={fetchLastPositions}
          refreshing={refreshing}
          data={positions}
          keyExtractor={item => item.veiculo.id.toString()}
          renderItem={({ item }) => (
            
          )}
        /> */}
    </Container>
  );
}