import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.header`
  display: flex;
  padding: ${metrics.padding}px;
  padding-top: 30px;
  background-color: rgba(0,0,0,0.05);
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0;
  padding: 0;
  box-shadow: none;
  height: 68px;
  min-height: 68px;
  filter: none;
  background-image: none;
  box-shadow: 0 1px 10px 0 rgba(50,50,50,.2);
`;

export const MenuToggler = styled.div`
  display: flex;
  flex: 1;
  /* height: 100%; */
  align-items: center;
  justify-content: flex-start;
  margin-left: 0.8em;

  /* padding: 0px 0.5em 0px 0.5em; */
  /* background-color: #ff401e; */
  div:hover {
    cursor: pointer;
  }
`;

export const Logo = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5em 0px 0.5em;
  /* background-color: #ff401e; */
  img {
    height: 70%;
  }
`;

export const VehiclesToggler = styled(MenuToggler)`
  justify-content: flex-end;
  margin-right: 1em;
`;