import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  
  border-radius: 2px;
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0,0,0,.1),0 1px 2px rgba(0,0,0,.18);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;

  ${props => props.danger && `
    background-color: #fbe1e3;
    border-color: #fbe1e3;
    color: #e73d4a;
  `}
  
  /* cursor: pointer; */
  label {
    flex: 1;
    font-size: 14px;
    line-height: 1.42857;
  }
`

export const Icon = styled(IconComponent)`
  margin-left: 5px;
  cursor: pointer;
  color: #c8b3b4;
  width: 30px;
`
