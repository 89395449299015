import { FaCar, FaAnchor, FaTemperatureHigh, FaCarBattery, FaMapMarkedAlt, FaRegAddressCard } from 'react-icons/fa';
import { MdMenu, MdHome, MdInput } from "react-icons/md";
import { IoIosPaper, IoIosCog, IoMdHelpBuoy, IoMdPower, IoIosKey, IoMdSpeedometer, IoMdCalendar, IoIosSearch, IoIosClose } from "react-icons/io";
import { IoCaretUp, IoCaretDown } from "react-icons/io5";
import { BsArrowUpRight } from "react-icons/bs";
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import { GiBattery100, GiBattery75, GiBattery50, GiBattery25, GiBattery0 } from "react-icons/gi";

export const Icon = (props) => {
  const {vendor, name, size = 40} = props
  return (
    <>
      {name == 'menu' ? (<MdMenu {...props} size={size} />) : null}
      {name == 'car' ? (<FaCar {...props} size={size} />) : null}
      {name == 'home' ? (<MdHome {...props} size={size} />) : null}
      {name == 'paper' ? (<IoIosPaper {...props} size={size} />) : null}
      {name == 'settings' ? (<IoIosCog {...props} size={size} />) : null}
      {name == 'sos' ? (<IoMdHelpBuoy {...props} size={size} />) : null}
      {name == 'logout' ? (<IoMdPower {...props} size={size} />) : null}
      {name == 'arrow-up-right' ? (<BsArrowUpRight {...props} size={size} />) : null}
      {name == 'anchor' ? (<FaAnchor {...props} size={size} />) : null}
      {name == 'key' ? (<IoIosKey {...props} size={size} />) : null}
      {name == 'speedometer' ? (<IoMdSpeedometer {...props} size={size} />) : null}
      {name == 'temperature' ? (<FaTemperatureHigh {...props} size={size} />) : null}
      {name == 'battery' ? (<FaCarBattery {...props} size={size} />) : null}
      {name == 'battery_level_100' ? (<GiBattery100 {...props} size={size} />) : null}
      {name == 'battery_level_75' ? (<GiBattery75 {...props} size={size} />) : null}
      {name == 'battery_level_50' ? (<GiBattery50 {...props} size={size} />) : null}
      {name == 'battery_level_25' ? (<GiBattery25 {...props} size={size} />) : null}
      {name == 'battery_level_0' ? (<GiBattery0 {...props} size={size} />) : null}
      {name == 'input' ? (<MdInput {...props} size={size} />) : null}
      {name == 'output' ? (<MdInput style={{'transform': 'rotateY(180deg)'}} {...props} size={size} />) : null}
      {name == 'calendar' ? (<IoMdCalendar {...props} size={size} />) : null}
      {name == 'map-marked' ? (<FaMapMarkedAlt {...props} size={size} />) : null}
      {name == 'driver-license' ? (<FaRegAddressCard {...props} size={size} />) : null}
      {name == 'search' ? (<IoIosSearch {...props} size={size} />) : null}
      {name == 'close' ? (<IoIosClose {...props} size={size} />) : null}
      {name == 'caret-up' ? (<IoCaretUp {...props} size={size} />) : null}
      {name == 'caret-down' ? (<IoCaretDown {...props} size={size} />) : null}
      {name == 'checkbox' ? (<GrCheckbox {...props} size={size} />) : null}
      {name == 'checkbox-selected' ? (<GrCheckboxSelected {...props} size={size} />) : null}
    </>
  )
}