import React, {Component, useEffect} from 'react';
// import Icon from "react-native-vector-icons/Ionicons";
import styles, { Container, Email, Name } from "./styles";
import avatarDefault from '~/assets/img/utils/avatar.png'
import { useDispatch, useSelector } from 'react-redux';
import { ProfileLoader } from '~/components/Loaders/VehicleLoader';
import { Schema } from "~/services/graphql/schema";
import { useQuery } from '@apollo/client';

export const Profile = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.session.session)

  const { loading, error, data: userData } = useQuery(Schema.GET_CURRENT_USER)
  useEffect(() => {
    if(userData?.currentUser){
      // console.log('Profile - currentUser: ', currentUser)
      dispatch.session.setUser(userData.currentUser)
    }
  }, [userData])

  if(loading){
    return (
      <Container>
        <ProfileLoader />
      </Container>
    )
  }

  return (
    <Container>
      <img src={user?.avatar ? user.avatar : avatarDefault}></img>
      <div>
        <Name>{user?.name}</Name>
        <Email>{user?.email}</Email>
      </div>
    </Container>
  );
}