import normalize from "./normalize";

export default {
  ComfortaaRegular: 'Comfortaa-Regular',
  RobotoRegular: 'Roboto-Regular',
  RobotoBold: 'Roboto-Bold',
  RobotoBlack: 'Roboto-Black',

  h1: '36px', // normalize(36),
  h2: '18px', // normalize(18),
  h3: '28px', // normalize(13),
  input: '15px', // normalize(15),

  xb: '20px', // normalize(20),
  xr: '18px', // normalize(18),
  bigger: '14px', // normalize(14),
  big: '12px', // normalize(12),
  regularBig: '11px', // normalize(11),
  regular: '10px', // normalize(10),
  small: '8px', // normalize(8),
  smaller: '7px', // normalize(7),
};
