import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  margin: 5px; //normalize(5);
  margin-top: 0px;
  margin-bottom: 10px;
  border: #DDD 1px solid;
  border-radius: 5px;
  box-shadow: 10px 10px 5px rgba(0,0,0,0.1);
  shadow-color: #000;
  shadow-opacity: 0.2;
  /* shadow-offset: { x: 10px; y: 10px }; */
  shadow-radius: 15px;
  elevation: 2;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${colors.primary};
  align-items: center;
  justify-content: space-between;
`

export const CarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  padding: ${metrics.padding}px;
  padding-left: 8px;

  p {
    font-size: ${fonts.bigger};
    font-weight: bold;
    margin: 7px;
  }
`

export const AnchorContainer = styled.a`
  cursor: pointer;
  padding-right: 0px;
  border-radius: 30px;
  padding: ${metrics.padding}px;
  ${props => props.isAnchored ? `
    background-color: ${colors.light};
    // margin: 5px;
    // margin-right: 0px;
    padding: 3px 5px 3px 5px;
    // padding-right: 4px;
  ` : ''}
`

export const GoToVehicleContainer = styled.a`
  cursor: pointer;
  border-radius: 25px;
  padding: ${metrics.padding}px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  // alignItems: center;
  // padding: normalize(10);
  // marginBottom: 1;
  // borderBottomWidth: 1;
  // borderBottomColor: colors.primaryLight;
  // backgroundColor: colors.primaryLight;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 3px 5px 3px 5px;
  align-items: center;
`

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px;
  align-items: center;
  justify-content: center;
`

export const EmptyPosition = styled.p`
  padding: 10px;
  margin-right: 5px;
  font-size: ${fonts.big};
`

const styles = {
  icon: {
    justifyContent: 'center',
    alignSelf: 'center',
    marginHorizontal: 5,
    marginRight: 10,
  },
  text: {
    flex: 1,
    marginRight: 5,
    fontSize: fonts.big,
    // borderColor: 'blue',
    // borderWidth: 1,
  },
  iconBadge: {
    marginRight: 5,
  },
  fontWhite: {
    color: 'white',
  },
}

export default styles;
