import { ApolloClient, HttpLink, InMemoryCache, split, concat, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { GRAPHQL } from '~/configs';
import { Database, Schema } from '../database';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({
  uri: GRAPHQL.SERVER.URI,
});

const wsLink = new WebSocketLink({
  uri: GRAPHQL.WEBSOCKET.URI,
  options: {
    reconnect: true,
    connectionParams: {
      // authToken: user.authToken,
    },
  }
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await Database.getObject(Schema.Token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.accessToken}` : "",
    }
  }
});

const errorLink = onError(({ networkError, graphQLErrors, forward, operation }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (graphQLErrors){
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': // error code is set to UNAUTHENTICATED when AuthenticationError thrown in resolver
          console.log('graphQLErrors - Unauthenticated');
          // TODO Tratar o caso da operaçao de delete do Database ser assícrono
          Database.deleteAllObjects(Schema.Token)
          history.go('/login')
          break
          // // modify the operation context with a new token
          // const oldHeaders = operation.getContext().headers;
          // operation.setContext({
          //   headers: {
          //     ...oldHeaders,
          //     authorization: getNewToken(),
          //   },
          // });
          // // retry the request, returning the new observable
          // return forward(operation);
      }
    }
  }
  // if (networkError.statusCode === 401) {
  //   console.log('networkError - Unauthenticated');
  // }
  // if (operation.operationName === "IgnoreErrorsQuery") {
  //   response.errors = undefined;
  // }
})

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  ApolloLink.from([authLink, errorLink, httpLink]),
);

export const gqlClient = new ApolloClient({
  // link: httpLink,
  link: splitLink,
  cache: new InMemoryCache(),
  // TODO Add token on header
  // headers: {
  //   authorization: localStorage.getItem('token') || ''
  // }
});

// export default client;
