import { useDispatch } from "react-redux";
import { MenuItem } from "./MenuItem";
import { Profile } from "./Profile";

export const LeftMenu = () => {
  const dispatch = useDispatch()

  const menuData = [
    {
      key: 1,
      icon: 'home',
      name: 'Início',
      screenName: 'Dashboard',
      visible: true,
      onClick: () => (console.log('oiii'))
    },
    {
      key: 2,
      icon: 'paper',
      name: 'Relatórios',
      screenName: 'Relatorios',
      visible: true,
    },
    {
      key: 3,
      icon: 'settings',
      name: 'Configurações',
      screenName: 'Configuracoes',
      visible: true,
    },
    {
      key: 4,
      icon: 'sos',
      name: 'Visualizar Modo SOS',
      screenName: 'ViewSosMode',
      visible: false,
    },
    {
      key: 5,
      icon: 'logout',
      name: 'Sair',
      screenName: 'Logout',
      visible: true,
      onClick: () => (dispatch.session.logoutUser())
    },
  ];

  return (
    <>
      <Profile/>
      {menuData.map((e,i,a) => (
        e.visible &&
          <MenuItem
            key={e.key} 
            icon={e.icon}
            title={e.name}
            onClick={e.onClick}
          />
      ))}
    </>
  )
}