import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.a`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  cursor: pointer;

  /* margin-left: 8px; */
  font-size: 14px;
  float: right;
  display: inline-block;
  outline: 0;
  text-shadow: none;
    color: #337ab7;

  :focus, :hover {
    color: #23527c;
    text-decoration: underline;
  }
`
