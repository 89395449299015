export const APP_NAME = "Rastreauto";
export const APP_NAME_STORAGE = "@rastreauto-web";

export const RASTREAUTO_POSITION = {
  lat: -5.1931506,
  lng: -37.3445699,
};

// TODO Remove __DEV__
const __DEV__ = true

export const BASE_URL =
// __DEV__ ?
  'http://127.0.0.1/';
//   :
  // 'https://app.rastreauto.com.br/':
//   'https://app.rastreauto.com.br/';
export const CLIENT =
// __DEV__ ?
  { ID: 2, SECRET: 'tIoH6TtbxK7sQh5SU78uDobhO1NUAZlN9KZueOsh' };
//   :
  // { ID: 2, SECRET: 'otgO2Fc6OBxXmlor8Qb2bWi0AIkLxAWLX0lFDkka' }:
//   { ID: 2, SECRET: 'otgO2Fc6OBxXmlor8Qb2bWi0AIkLxAWLX0lFDkka' };
export const GRAPHQL = {
  SERVER: {
    URI: __DEV__ ? 'http://localhost:4000/graphql' : '',
  },
  WEBSOCKET: {
    URI: __DEV__ ? 'ws://localhost:4000/graphql' : '',
  },
}
export const BUGSNAG_ID = 'b6a6fda3daa174af77f5e75788dd7cab';
export const ONESIGNAL_APPID = 'a20b4f2a-e953-4681-a4ba-f9bd159d65e5';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyB9-oAIsqX2ToT7GsaEKgbJSRvZm7tpam8';
export const LANGUAGE = 'pt-br';
export const DATE_FORMAT = {
  SQL: {
    DATETIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
  },
  HUMAN: {
    DATETIME: 'DD/MM/YYYY HH:mm:ss',
    DATE: 'DD/MM/YYYY',
  },
  FILE: {
    DATETIME: 'YYYY_MM_DD_HH_mm_ss',
    DATE: 'YYYY_MM_DD',
  },
}
export const ONE_SECOND = 1000;
export const TIME = {
  TWENTY_SECONDS: (20 * ONE_SECOND),
  THIRTY_SECONDS: (30 * ONE_SECOND),
  ONE_MINUTE: (60 * ONE_SECOND),
};
export const USER = {
  TIPO: {
    ADMINISTRADOR: 4,
    CENTRAL: 7,
    CLIENTE: 3,
    MONITORAMENTO: 5,
    MULTI_CLIENT: 6,
  },
}
