import normalize from "./normalize";

export default {
  padding: 5, //normalize(10),
  headerHeight: 44,//normalize(44),
  headerPadding: 0,//normalize(0),
  inputPaddingTop: 0,
  inputTop: 20,
  modalHeight: 0,
  tabBarHeight: 50,//normalize(50),
};
