import React, { useState } from 'react';
import { icons } from '~/styles';
import { Icon } from '../icon';
import {Container} from './styles';

export const Link = (props) => {
  const { children, onClick } = props
  
  function handleOnClick(e) {
    onClick()
  }

  return (
    <Container {...props} onClick={handleOnClick}>
      {children}
    </Container>
  )
}