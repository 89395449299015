import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const EmptyContainer = styled.div`
  display: flex;
  height: 94vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
`;

export const FlatListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
  
  /* :hover { */
    overflow: auto;
  /* } */
`;
