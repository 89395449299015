import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: 8px; */
  color: #8290a3;
  /* padding-left: 30px; */
  /* margin-bottom: 15px; */
  cursor: pointer;
  font-size: 14px;
  /* transition: all .3s; */
  
  label {
    padding-left: 3px;
  }
`
