import React from 'react';
import {Container} from './styles';
import { ClipLoader } from "react-spinners";
import { colors } from '~/styles';

export const Button = (props) => {
  const { children, loading, secundary } = props
  // console.log('Button - loading: ', loading)

  return (
    <Container {...props}>
      { loading
        ? <ClipLoader loading={loading} color={secundary ? colors.primary : colors.white} size="16px" margin="4px" />
        : children
      }
    </Container>
  )
}