// import { _picker } from "~/helpers/dialog";

// import Toast from "~/helpers/toast";
// import { formatError } from "~/helpers/exception";
// import { bugsnag } from "~/helpers/bugsnag";
// import { TIME } from "~/configs";

// import { Creators as CreatorsUsers, isAuthenticated } from "./session";
// import { Creators as CreatorsOffline } from "./offline";

// import { createSelector } from "reselect";
// import { createSelectorCreator, defaultMemoize } from 'reselect';
// import { isEqual } from 'lodash';



// // import { bugsnag, setUser as setUserBugsnag} from '~/services/helpers/bugsnag';
// import { deviceId } from '~/services/helpers/onesignal';
// import { routes, api } from '~/services/api/routes';
// // import { Database, Schema } from '~/services/database';
// // import { NavigationService } from '~/services/navigation/service';
// import { Toast } from '~/services/helpers/toast';
// import Routes from '~/services/routes';
// import { Database, Schema } from '~/services/database';
// import history from '~/services/routes/history';
import { gqlClient } from "~/services/graphql/apollo";
import { Schema } from "~/services/graphql/schema";
// import { Toast } from '~/services/helpers/toast';
// import { formatError } from '~/services/helpers/exception';

export const vehicles = {
  state: {
    vehicles: {
      loading: false,
      hasError: false,
      errors: null,
      list: [],
      lastPositionsLoading: false,
      lastPositions: {},
      anchorStatus: {},
      offset: 0,
      limit: 10,
      searchingFor: '',
      orderBy: {
        loading: false,
        field: 'vehicle',
        order: 'asc',
      },
    },
    watch: {
      active: true,
      // index: 0,
      vehicle: null,
    },
  },
  reducers: {
    fetchVehiclesPending: (state, payload) => ({...state, vehicles: { ...state.vehicles, loading: true, error: false, errors: null } }),
    fetchVehiclesFulfiled: (state, payload) => ({...state, vehicles: { ...state.vehicles, loading: false } }),
    fetchVehiclesRejected: (state, payload) => ({...state, vehicles: { ...state.vehicles, loading: false, error: true, errors: payload } }),
    setVehicles: (state, payload) => ({...state, vehicles: { ...state.vehicles, list: payload } }),
    setLastPosition: (state, payload) => ({...state, vehicles: { ...state.vehicles, lastPositions: {...state.vehicles.lastPositions, [payload.vehicleId]: payload}}}),
    setAnchorStatus: (state, payload) => ({...state, vehicles: { ...state.vehicles, anchorStatus: {...state.vehicles.anchorStatus, [payload.vehicleId]: payload}}}),
    setPositions: (state, payload) => ({...state, vehicles: { ...state.vehicles, list: payload } }),
    setOrderByPositions: (state, payload) => ({...state, vehicles: { ...state.vehicles, orderBy: payload } }),
    orderByPositionsStart: (state, payload) => ({...state, vehicles: { ...state.vehicles, orderBy: {...state.vehicles.orderBy, loading: true } }}),
    orderByPositionsFinish: (state, payload) => ({...state, vehicles: { ...state.vehicles, orderBy: {...state.vehicles.orderBy, loading: false } }}),
    setSearchPosition: (state, payload) => ({...state, vehicles: {...state.vehicles, searchingFor: payload }}),
    clearSearchPosition: (state, payload) => ({...state, vehicles: {...state.vehicles, searchingFor: '' }}),
    setWatchVehicle: (state, payload) => ({...state, vehicle: payload }),
    setWatchVehicleIndex: (state, payload) => ({...state, vehicleIndex: payload }),
    setWatchVehicleActive: (state, payload) => ({...state, vehicleWatch: payload }),
    clearVehicles: (state, payload) => ({...state, vehicles: { ...state.vehicles, list: [], searchingFor: '' } }),
    clearAll: (state, payload) => ({...vehicles.state}),
    
    // loginUserPending: (state, payload) => ({...state, login: {...state.login, loading: true, hasError: false, errors: null},}),
    // loginUserFulfiled: (state, payload) => ({...state, login: {...state.login, loading: false, hasError: false, errors: null},}),
    // loginUserRejected: (state, payload) => {
    //   // converte os errors do feathers para um formato a ser mostrado no Front
    //   // const { "name.first":first, "name.last":last, phone, email } = payload.errors;
    //   // const errors = { global: payload.message, name: { first,last }, phone, email };
    //   // return {...state, errors: errors, loading: false};
    //   return { ...state, login: {...state.login, loading: false, hasError: true, errors: payload}, }; // redirect: false
    // },
    // clearRecovery: (state, payload) => ({...state, recovery: {...state.recovery, email: '',},}),
  },
  effects: dispatch => ({
    async getVehicles(payload, rootState) {
      // const { email, password } = rootState.vehicles.login;
      dispatch.vehicles.fetchVehiclesPending();
      try{
        let result = await gqlClient.query({query: Schema.GET_VEHICLES})
        console.log(result)
        dispatch.vehicles.fetchVehiclesFulfiled();
        dispatch.vehicles.setVehicles(result.data.vehicles)
        // console.warn(res)
        // dispatch.vehicles.loginUserFulfiled();
        // // const { token, user, subscription } = res.data
        // const token = res.data
        // dispatch.vehicles.setToken(token);
        // await Database.deleteAllAndSaveObject(Schema.Token, token);
        // // if(user){
        // //   dispatch.vehicles.setUser(user);
        // //   await Database.deleteAllAndSaveObject(Schema.User, user);
        // //   setUserBugsnag({id: user.id, nome: user.name, email: user.email}); // Setando o usuário pro Bugsnag.
        // // }
        // // if(subscription){
        // //   dispatch.vehicles.setSubscription(subscription);
        // //   await Database.deleteAllAndSaveObject(Schema.Subscription, subscription);
        // // }
        // dispatch.vehicles.setLoggedIn(true)
        
        // history.push('/');
        // NavigationService.navigate('Dashboard');

        // dispatch.subscriptions.checkExpirationSubscription();
      } catch(err) {
        console.warn(err)
        dispatch.vehicles.fetchVehiclesRejected(err); // err.response.data
      }
    },
    async toggleAnchorStatus(payload, rootState) {
      // const { email, password } = rootState.vehicles.login;
      // dispatch.vehicles.fetchVehiclesPending();
      try{
        let result = await gqlClient.mutate({mutation: Schema.TOGGLE_ANCHOR_STATUS, variables: {vehicleId: Number(payload)}})
        console.log('vehicles - toggleAnchorStatus - result: ', result)
      } catch(err) {
        console.warn(err)
        // dispatch.vehicles.loginUserRejected(err); // err.response.data
      }
    },
  })
}

export const sortVehicles = ({lastPositions, orderBy}) => {
  // console.log('sortVehicles - lastPositions|orderBy', lastPositions, orderBy)
  return function(a, b) {
    const lastPositionA = lastPositions[a.id]
    const lastPositionB = lastPositions[b.id]

    if (lastPositionA && !lastPositionB) return -1;
    if (!lastPositionA && lastPositionB) return 1;
    if (!lastPositionA && !lastPositionB && a.description < b.description) return -1;
    if (!lastPositionA && !lastPositionB && a.description > b.description) return 1;

    // compareLessVehicle
    if(orderBy.field == 'vehicle' && orderBy.order == 'asc'){
      if (a.description < b.description) return -1;
      if (a.description > b.description) return 1;
      return 0;
    }

    // compareMoreVehicle
    if(orderBy.field == 'vehicle' && orderBy.order == 'desc'){
      if (a.description > b.description) return -1;
      if (a.description < b.description) return 1;
      return 0;
    }

    // compareLessDatetime
    if(orderBy.field == 'datetime' && orderBy.order == 'asc'){
      if (lastPositionA.datetime < lastPositionB.datetime) return -1;
      if (lastPositionA.datetime > lastPositionB.datetime) return 1;
      return 0;
    }

    // compareMoreDatetime
    if(orderBy.field == 'datetime' && orderBy.order == 'desc'){
      if (lastPositionA.datetime > lastPositionB.datetime) return -1;
      if (lastPositionA.datetime < lastPositionB.datetime) return 1;
      return 0;
    }

    // compareLessIgnition
    if(orderBy.field == 'ignition' && orderBy.order == 'asc'){
      if (!lastPositionA.ignition && lastPositionB.ignition) return -1;
      if (lastPositionA.ignition == lastPositionB.ignition && a.description < b.description) return -1;
      if (lastPositionA.ignition && !lastPositionB.ignition) return 1;
      return 0;
    }

    // compareMoreIgnition
    if(orderBy.field == 'ignition' && orderBy.order == 'desc'){
      if (lastPositionA.ignition && !lastPositionB.ignition) return -1;
      if (lastPositionA.ignition == lastPositionB.ignition && a.description < b.description) return -1;
      if (!lastPositionA.ignition && lastPositionB.ignition) return 1;
      return 0;
    }
  }
};
