import { BASE_URL } from "~/configs";
import { create } from "axios";
import history from "~/services/routes/history";
import { Database, Schema } from "../database";
// import { reportRequestException } from "../helpers/bugsnag";

const api = create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    'Accept': 'application/json',
  },
});

const status = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
};

api.interceptors.request.use(async config => {
  const token = await Database.getObject(Schema.Token);
  console.log('api - request - token: ', token);
  if (token) {
    config.headers.Authorization = `Bearer ${token.access}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  console.warn('api - interceptors.request - error: ', error)
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  console.warn('api - interceptors.response - response: ', response)
  if(response.status === status.SUCCESS){
    if(response.data && response.data.errors){
      response['ok'] = false;
      console.warn('api - interceptors.response - response.data.errors: ', response.data.errors)
      const keys = Object.keys(response.data.errors)
      const firstError = response.data.errors[keys[0]]
      console.warn('api - success but bad request - firstError: ', firstError)
      Toast.show(firstError.properties.message)
      return Promise.reject(firstError);
    } else {
      response['ok'] = true;
    }
  }

  return response;
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  // console.warn('Error: ', error, typeof(error)); // JSON.stringify(error)
  console.warn('api - interceptors.response - error.response: ', error.response);
  if(error?.message === 'Network Error'){
    // TODO Toast.show('Não foi possível concluir a requisição. Por favor, verifique sua conexão com a internet.')
  } else if (error?.response?.status === status.BAD_REQUEST) {
    error.response['ok'] = false;
    console.warn('api - bad request - error.response: ', error.response)
    if(error?.response?.data){
      // TODO Toast.show(error.response.data[0].message)
    }
  } else if (error?.response?.status === status.UNAUTHORIZED) {
    await Database.deleteAllObjects(Schema.Token);
    await Database.deleteAllObjects(Schema.User);
    // NavigationService.navigate('Login');
    // swal({
    //     title: "Session Expired",
    //     text: "Your session has expired. Would you like to be redirected to the login page?",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#DD6B55",
    //     confirmButtonText: "Yes",
    //     closeOnConfirm: false
    // }, function(){
    //     window.location = '/login';
    // });
    console.log('Foi erro 401');
    history.push('/login');
  } else if (error?.response?.status === status.SERVER_ERROR) {
    // Alert.alert('Erro', error.response.data.error.message)
    reportRequestException(response);
    // TODO Toast.show('Não foi possível concluir a requisição.')
  }
  if(error?.response){
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
});

// // Middleware para alterar os dados da resposta antes de chegar na aplicação.
// api.addResponseTransform(response => {
//   reportRequestException(response);
//   if(!response.ok) throw response;
// });

export default api;
