import React, { useState } from 'react';
import { icons } from '~/styles';
import { Icon } from '../icon';
import {Container} from './styles';

export const Checkbox = (props) => {
  const { children, value, onClick } = props

  const [selected, setSelected] = useState(typeof(value) !== 'undefined' ? value : false);
  
  function handleOnClick(e) {
    console.log('Checkbox', !selected)
    setSelected(!selected)
    onClick(!selected)
  }

  return (
    <Container {...props}>
      {/* <input type="checkbox" name={name} value={selected} checked={selected} onChange={handleOnClick} /> */}
      {!selected && (<Icon name='checkbox' size={icons.regular} onClick={handleOnClick} />)}
      {selected && (<Icon name='checkbox-selected' size={icons.regular} onClick={handleOnClick} />)}
      <label>{children}</label>
    </Container>
  )
}