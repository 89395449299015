import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
import { Header3 } from "~/styles/global";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  /* border: blue 2px solid; */
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #e9edef;
  height: 100vh;
`

export const Logo = styled.div`
  margin-top: 70px;
  img {
    width: 280px;
    height: 72px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto 10px auto;
  border-radius: 7px;
  background-color: ${colors.white};
  padding: 30px;
  padding-top: 30px;
  width: 330px; // TODO Media Queries
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: 1px solid transparent;
    background-color: #dde3ec;
    height: 29px;
    color: #8290a3;
    outline: 0;
    font-size: 14px;
    /* line-height: 1.42857; */
    padding: 6px 12px;
    border-radius: 4px;
    margin: 5px 0px;
    /* padding: 6px 12px;
    font-size: 14px; */

    :focus {
      border: 1px solid #c3ccda;
    }
  }
`

export const SubHeader = styled.p`
  margin: 20px 0;
  color: #333;
  font-family: "Open Sans",sans-serif;
  direction: ltr;
  font-size: 14px;
  line-height: 1.42857;
`

export const FormActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px;
`

export const Copyright = styled.div`
  /* text-align: center; */
  /* margin: 0 auto 30px 0; */
  padding: 10px;
  color: #7a8ca5;
  font-size: 13px;
`