import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  display: flex;
  padding: ${metrics.padding}px;
  /* padding-horizontal: ${metrics.padding}; */
  flex-direction: row;
  align-items: center;
  background-color: ${colors.primary};
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding: ${metrics.padding}px;
  padding-top: 30px;
  background-color: rgba(0,0,0,0.05);
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0;
  padding: 0;
  box-shadow: none;
  height: 68px;
  min-height: 68px;
  filter: none;
  background-image: none;
  box-shadow: 0 1px 10px 0 rgba(50,50,50,.2); */

  img {
    width: 56px; //normalize(56);
    height: 56px; //normalize(56);
    border-radius: 28px; //normalize(28);
    /* margin-right: ${metrics.padding}px; */
    margin-right: 10px;
  }

  div {
    flex: 1,
  }
`;

export const Name = styled.p`
  font-weight: bold;
  font-size: ${fonts.bigger};
  color: ${colors.darker};
  /* margin-top: 10px; */
`

export const Email = styled.p`
  font-size: ${fonts.big};
  color: ${colors.darker};
  margin-top: 5px;
`