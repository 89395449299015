import styled from "styled-components";
import { colors, metrics, fonts, normalize } from "~/styles";

// const { ComfortaaRegular, RobotoRegular, RobotoBlack, h1, h2, h3 } = fonts;

export const Container = styled.div`
  flex: 1;
  padding: ${metrics.padding}px;
  padding-bottom: ${metrics.padding+8}px;
  background-color: ${colors.white};
`;

export const Header1 = styled.h1`
  font-size: ${fonts.h1};
  color: ${colors.primary};
`;

export const Header2 = styled.h2`
  font-size: ${fonts.h2};
  color: ${colors.primary};
`;

export const Header3 = styled.h3`
  font-size: ${fonts.h3};
  color: ${props => props.color ? props.color : colors.black};
  font-weight: 400;
  /* text-transform: uppercase; */
`;

export const Header4 = styled.h4`
  font-size: 25px;
  color: ${colors.primary};
`;
export const Text = styled.p`
  font-size: ${fonts.regularBig};
  color: ${colors.gray1};
`;

export const Input = styled.input`
  height: 45;
  /* font-size: ${fonts.input} */
  /* background-color: rgba(225,225,225,0.7); */
  border-bottom-color: ${colors.primary};
  border-bottom-width: 2px;
  /* margin-bottom: 10px; */
  margin: 7px 10px;
  /* padding: 20px; */
  color: ${colors.darker};
  /* shadow-color: #000;
  shadow-offset: {width: 0, height: 2};
  shadow-opacity: 0.25;
  shadow-radius: 3.84;
  elevation: 5; */
`;