import normalize from "./normalize";

export default {
  xbb: '40px', // normalize(30),
  xb: '30px', // normalize(30),
  xr: '28px', // normalize(28),
  bigger: '24px', // normalize(24),
  big: '20px', // normalize(20),
  regular: '18px', // normalize(18),
  small: '16px', // normalize(16),
  smaller: '12px', // normalize(12),
};
