// import { bugsnag, setUser as setUserBugsnag} from '~/services/helpers/bugsnag';
import { deviceId } from '~/services/helpers/onesignal';
import { routes, api } from '~/services/api/routes';
// import { Database, Schema } from '~/services/database';
// import { NavigationService } from '~/services/navigation/service';
import { Toast } from '~/services/helpers/toast';
import Routes from '~/services/routes';
import { Database, Schema } from '~/services/database';
import history from '~/services/routes/history';
import { gqlClient } from '~/services/graphql/apollo';
import { Schema as SchemaGql } from '~/services/graphql/schema';
// import { Toast } from '~/services/helpers/toast';
// import { formatError } from '~/services/helpers/exception';

export const isAuthenticated = async () => await Database.getObject(Schema.Token) !== null;

export const session = {
  state: {
    login: {
      loading: false,
      hasError: false,
      errors: null,
      email: '',
      password: '',
      rememberMe: false,
    },
    session: {
      token: {},
      user: {},
      subscription: {},
      loggedIn: false,
    },
    password: {
      submitting: false,
      hasError: false,
      errors: null,
      actualPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    recovery: {
      submitting: false,
      hasError: false,
      errors: null,
      showModalForgot: false,
      email: '',
    }
  },
  reducers: {
    setEmail: (state, payload) => ({...state, login: {...state.login, email: payload},}),
    setPassword: (state, payload) => ({...state, login: {...state.login, password: payload},}),
    setRememberMe: (state, payload) => ({...state, login: {...state.login, rememberMe: payload},}),
    loginUserPending: (state, payload) => ({...state, login: {...state.login, loading: true, hasError: false, errors: null},}),
    loginUserFulfiled: (state, payload) => ({...state, login: {...state.login, loading: false, hasError: false, errors: null},}),
    loginUserRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      // const { "name.first":first, "name.last":last, phone, email } = payload.errors;
      // const errors = { global: payload.message, name: { first,last }, phone, email };
      // return {...state, errors: errors, loading: false};
      return { ...state, login: {...state.login, loading: false, hasError: true, errors: payload}, }; // redirect: false
    },
    clearLoginInputs: (state, payload) => ({...state, login: {...state.login, email: '', password: '', rememberMe: false},}),
    setToken: (state, payload) => ({...state, session: {...state.session, token: payload},}),
    setUser: (state, payload) => ({...state, session: {...state.session, user: payload},}),
    setSubscription: (state, payload) => ({...state, session: {...state.session, subscription: payload},}),
    setLoggedIn: (state, payload) => ({...state, session: {...state.session, loggedIn: payload},}),

    setActualPassword: (state, payload) => ({...state, password: {...state.password, actualPassword: payload},}),
    setNewPassword: (state, payload) => ({...state, password: {...state.password, newPassword: payload},}),
    setConfirmPassword: (state, payload) => ({...state, password: {...state.password, confirmPassword: payload},}),
    changePasswordPending: (state, payload) => ({...state, password: {...state.password, submitting: true, hasError: false, errors: null},}),
    changePasswordFulfiled: (state, payload) => ({...state, password: {...state.password, submitting: false, hasError: false, errors: null},}),
    changePasswordRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      // const { "name.first":first, "name.last":last, phone, email } = payload.errors;
      // const errors = { global: payload.message, name: { first,last }, phone, email };
      // return {
      //   ...state,
      //   errors: errors,
      //   submitting: false
      // };
      return { ...state, password: {...state.password, submitting: false, hasError: true, errors: payload}, }; // redirect: false
    },
    clearPasswords: (state, payload) => ({...state, password: {...state.password, actualPassword: '', newPassword: '', confirmPassword: '',},}),
    
    setEmailRecoveryPassword: (state, payload) => ({...state, recovery: {...state.recovery, email: payload,},}),
    setShowModalForgotPassword: (state, payload) => ({...state, recovery: {...state.recovery, showModalForgot: payload,},}),
    recoveryPasswordPending: (state, payload) => ({...state, recovery: {...state.recovery, submitting: true, hasError: false, errors: null},}),
    recoveryPasswordFulfiled: (state, payload) => ({...state, recovery: {...state.recovery, submitting: false, hasError: false, errors: null},}),
    recoveryPasswordRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      // const { "name.first":first, "name.last":last, phone, email } = payload.errors;
      // const errors = { global: payload.message, name: { first,last }, phone, email };
      // return {
        //   ...state,
        //   errors: errors,
        //   submitting: false
        // };
        return { ...state, recovery: {...state.recovery, submitting: false, hasError: true, errors: payload}, }; // redirect: false
      },
    clearRecovery: (state, payload) => ({...state, recovery: {...state.recovery, email: '',},}),
  },
  effects: dispatch => ({
    async loginUser(payload, rootState) {
      const { email, password, rememberMe } = rootState.session.login;
      dispatch.session.loginUserPending();
      try{
        let result = await gqlClient.mutate({mutation: SchemaGql.LOGIN, variables: {email, password}})
        console.warn(result)
        const token = result.data.login
        dispatch.session.setToken(token);
        await Database.deleteAllAndSaveObject(Schema.Token, token);
        dispatch.session.setLoggedIn(true);
        dispatch.session.loginUserFulfiled();
        history.push('/');
        if(!rememberMe){
          dispatch.session.clearLoginInputs()
        }
      } catch(err) {
        console.warn(err)
        dispatch.session.setLoggedIn(false);
        dispatch.session.loginUserRejected(err);
      }

      // return api({...routes.session.login, data: routes.session.login.data({ email, password, device: deviceId })})
      //   .then(async (res) => {
      //     console.warn(res)
      //     dispatch.session.loginUserFulfiled();
      //     // const { token, user, subscription } = res.data
      //     const token = res.data
      //     dispatch.session.setToken(token);
      //     await Database.deleteAllAndSaveObject(Schema.Token, token);
      //     // if(user){
      //     //   dispatch.session.setUser(user);
      //     //   await Database.deleteAllAndSaveObject(Schema.User, user);
      //     //   setUserBugsnag({id: user.id, nome: user.name, email: user.email}); // Setando o usuário pro Bugsnag.
      //     // }
      //     // if(subscription){
      //     //   dispatch.session.setSubscription(subscription);
      //     //   await Database.deleteAllAndSaveObject(Schema.Subscription, subscription);
      //     // }
      //     dispatch.session.setLoggedIn(true)
          
      //     history.push('/');
      //     // NavigationService.navigate('Dashboard');

      //     // dispatch.subscriptions.checkExpirationSubscription();
      //   })
      //   .catch(err => {
      //     console.warn(err)
      //     dispatch.session.loginUserRejected(err); // err.response.data
      //   });
    },
    getMeUser(payload, rootState) {
      return api({...routes.users.getMeUser})
        .then(async (res) => {
          console.warn(res)
          dispatch.session.loginUserFulfiled();
          const { token, user, subscription } = res.data
          dispatch.session.setToken(token);
          // await Database.deleteAllAndSaveObject(Schema.Token, token);
          // dispatch.session.setUser(user);
          // await Database.deleteAllAndSaveObject(Schema.User, user);
          // if(subscription){
          //   dispatch.session.setSubscription(subscription);
            // await Database.deleteAllAndSaveObject(Schema.Subscription, subscription);
          // }
          // setUserBugsnag({id: user.id, nome: user.name, email: user.email}); // Setando o usuário pro Bugsnag.
          dispatch.session.setLoggedIn(true)
          NavigationService.navigate('Dashboard');

          dispatch.subscriptions.checkExpirationSubscription();
        })
        .catch(err => {
          console.warn(err)
          dispatch.session.loginUserRejected(err); // err.response.data
        });
    },
    async refreshToken(payload, rootState){
      let token = await Database.getObject(Schema.Token);

      return api({...routes.session.refreshToken, data: routes.session.refreshToken.data({refresh_token: token.refresh_token})})
        .then(async res =>{
          console.warn(res.data)
          const { token, user, subscription } = res.data
          dispatch.session.setToken(token);
          // dispatch.session.setUser(user);
          // dispatch.session.setSubscription(subscription);
          // console.warn('session - refreshToken - subscription: ', subscription);
          // console.error(subscription)
          // await Database.deleteAllAndSaveObject(Schema.Token, token);
          // await Database.deleteAllAndSaveObject(Schema.User, user);
          // if(subscription){
            // await Database.deleteAllAndSaveObject(Schema.Subscription, subscription);
          // }

          // dispatch.subscriptions.checkExpirationSubscription();
        })
        .catch(err =>{
          console.warn(err)
        });
    },
    async logoutUser(payload, rootState) {
      dispatch.session.loginUserPending();
      try{
        // let result = await gqlClient.mutate({mutation: SchemaGql.LOGIN, variables: {email, password}})
        // console.warn(result)
        // const token = result.data.login
        await gqlClient.resetStore()
        dispatch.session.setToken(null);
        await Database.deleteAllObjects(Schema.Token);
        dispatch.session.setLoggedIn(false);
        dispatch.vehicles.clearAll()
        dispatch.ui.setHeaderMenuLeft(false)
        dispatch.ui.setHeaderMenuRight(false)
        dispatch.session.loginUserFulfiled();
        history.push('/login');
      } catch(err) {
        console.warn(err)
        dispatch.session.setLoggedIn(false);
        dispatch.session.loginUserRejected(err); // err.response.data
      }

      // // Revoke All Tokens
      // return api(routes.logout, { device: deviceId })
      //   .then(async res => {
      //     console.warn(res)
      //   })
      //   .catch(async err =>{
      //     console.warn(err)
      //   })
      //   .then(async () => {
      //     // always executed
      //     // await Database.deleteAllObjects(Schema.Token);
      //     // await Database.deleteAllObjects(Schema.User);
      //     // await Database.deleteAllObjects(Schema.Subscription);
      //     dispatch.session.setLoggedIn(false);
      //     history.push('/login')
      //     // NavigationService.navigate('Login');
      //   });
    },
    changePassword(payload, rootState) {
      Keyboard.dismiss();

      const {actualPassword, newPassword, confirmPassword} = rootState.session.password;
      dispatch.session.changePasswordPending();
      return api(routes.changePassword, {actualPassword, newPassword, confirmPassword})
        .then(async (res) => {
          console.warn(res)
          dispatch.session.changePasswordFulfiled();
          Toast.show('Senha alterada com sucesso!')
          NavigationService.goBack();
          dispatch.session.clearPasswords()
        })
        .catch(err => {
          console.warn(err)
          dispatch.session.changePasswordRejected(err);
        });
    },
    recoveryPassword(payload, rootState) {
      Keyboard.dismiss();

      const {email} = rootState.session.recovery;
      dispatch.session.recoveryPasswordPending();
      return api(routes.recoveryPassword, {email})
        .then(async (res) => {
          console.warn(res)
          dispatch.session.setShowModalForgotPassword(false)
          dispatch.session.clearRecovery()
          dispatch.session.recoveryPasswordFulfiled();
          Toast.show('Email enviado com sucesso!')
        })
        .catch(err => {
          console.warn(err)
          dispatch.session.recoveryPasswordRejected(err);
        });
    },
    uploadPhoto(payload, rootState) {
      dispatch.session.recoveryPasswordPending();
      const imagesData = new FormData();
      imagesData.append('file', payload);
      return api(routes.uploadFile, imagesData)
        .then(async (res) => {
          console.warn(res)
          // dispatch.session.setShowModalForgotPassword(false)
          // dispatch.session.clearRecovery()
          let { user } = rootState.session.session
          user.file_id = res.data.id
          dispatch.session.setUser({...user})
          dispatch.session.recoveryPasswordFulfiled();
          Toast.show('Foto enviada com sucesso!')
          // api(routes.downloadFile, imagesData)
        })
        .catch(err => {
          console.warn(err)
          dispatch.session.recoveryPasswordRejected(err);
        });
    },
  }),
}