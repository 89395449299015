import { gql } from '@apollo/client';

// const EXCHANGE_RATES = gql`
//   query GetExchangeRates {
//     rates(currency: "USD") {
//       currency
//       rate
//     }
//   }
// `;

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: {email: $email, password: $password}) {
      accessToken
    }
  }
`

// const LOGOUT = gql`
//   mutation logout() {
//     login() {}
//   }
// `

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      email
    }
  }
`

const GET_VEHICLES = gql`
  query GetVehicles($clientId: Int, $regionalId: Int, $unityId: Int) {
    vehicles (input: {clientId: $clientId, regionalId: $regionalId, unityId: $unityId}) {
      id
      clientId
      description
    }
  }
`

const GET_LAST_POSITION = gql`
  query GetLastPosition($vehicleId: Int!) {
    lastPosition (vehicleId: $vehicleId){
      vehicleId
      vehicle {
        id
        description
      }
      driver {
        name
      }
      address
      altitude
      course
      datetime
      driverId
      hourmeter
      ignition
      inputDigital1
      inputDigital2
      latitude
      longitude
      odometer
      outputDigital1
      power
      serialNumber
      speed
      temperature
      voltageA
      voltageP
    }
  }
`

const POSITION_ADDED_SUBSCRIPTION = gql`
  subscription OnPositionAdded($vehicleId: Int!) {
    positionAdded(vehicleId: $vehicleId) {
      vehicleId
      vehicle {
        id
        description
      }
      driver {
        name
      }
      address
      altitude
      course
      datetime
      driverId
      hourmeter
      ignition
      inputDigital1
      inputDigital2
      latitude
      longitude
      odometer
      outputDigital1
      power
      serialNumber
      speed
      temperature
      voltageA
      voltageP
    }
  }
`;


const GET_ANCHOR_STATUS = gql`
  query GetAnchorStatus($vehicleId: Int!) {
    status (vehicleId: $vehicleId){
      vehicleId
      vehicle {
        id
      }
      status
      # createdAt
      updatedAt
    }
  }
`

const TOGGLE_ANCHOR_STATUS = gql`
  mutation ToggleAnchorStatus($vehicleId: Int!) {
    toggleStatus(vehicleId: $vehicleId) {
      id
      vehicleId
      status
    }
  }
`

const ANCHOR_CHANGED_SUBSCRIPTION = gql`
  subscription OnAnchorChanged($vehicleId: Int!) {
    anchorChanged(vehicleId: $vehicleId) {
      # vehicle {
      #   id
      #   description
      # }
      vehicleId
      status
    }
  }
`;

export const Schema = {
  LOGIN,
  // LOGOUT,
  GET_CURRENT_USER,
  GET_VEHICLES,
  GET_LAST_POSITION,
  GET_ANCHOR_STATUS,
  TOGGLE_ANCHOR_STATUS,
  POSITION_ADDED_SUBSCRIPTION,
  ANCHOR_CHANGED_SUBSCRIPTION,
}
