import React from 'react';
import { Icon } from '~/components/icon';
import { icons } from '~/styles';
// import Ignicao from '~/components/grid/Ignicao';

// import { Container } from './styles';

export default function Callout({children, item, isVisible, zIndex, onClickToClose}) {
  if(isVisible){
    const width = 175;
    const halfWidth = width/2;

    return (
      <div
        className="gm-style-iw gm-style-iw-c"
        style={{
          'backgroundColor': 'white',
          'padding': '10px',
          'display': 'flex',
          'width': width,
          'borderRadius': '13px',
          'position': 'absolute',
          'top': '-153px',
          // 'right': '-95px',
          'zIndex': zIndex,
        }}>
        <button
          onClick={onClickToClose}
          draggable="false" title="Fechar" aria-label="Fechar" type="button" className="gm-ui-hover-effect"
          style={{
            'background': 'none', 
            'display': 'block', 
            'border': '0px', 'margin': 
            '0px', 'padding': '0px', 
            'position': 'absolute', 
            'cursor': 'pointer', 
            'userSelect': 'none', 'top': '-1px', 'right': '0px', 'width': '30px', 'height': '30px',}}>
            <Icon
              name='close'
              size={icons.regular}
            />
            {/* <img
              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A"
              style={{
                'pointerEvents': 'none', 'display': 'block', 'width': '14px', 'height': '14px', 'margin': '8px',}}/> */}
        </button>
        <div
          className="gm-style-iw-d"
          style={{'maxWidth': '636px', 'maxHeight': '331px',}}>
          {children}
        </div>
      </div>
    );
  }
  return null;
}
