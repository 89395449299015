import React, {useEffect, useState} from 'react';

import styles, {
  AnchorContainer, Container, HeaderContainer, GoToVehicleContainer, CarContainer,
  BodyContainer, InfoContainer, BadgesContainer, EmptyPosition,
} from "./styles";
import { normalize, icons, fonts } from '~/styles';
import { Icon } from '~/components/icon';
import { Badge } from './Badge';

import { useQuery, useSubscription } from '@apollo/client';
import { Schema } from '~/services/graphql/schema';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from '~/components/status';
import { diffNowHours, getDateFromIso, formatDateTimeHuman } from '~/services/helpers/date';
import { VehicleLoader, InfoLoader, BadgeLoader } from '~/components/Loaders/VehicleLoader';

export const Vehicle = (props) => {
  const dispatch = useDispatch()
  const { vehicle, lastPosition, anchor, index } = props;
  // console.warn('Vehicle - render - vehicle: ', vehicle);
  // console.warn('Vehicle - lastPositionsLoading: ', vehicle.description, lastPositionsLoading);

  const { loading: lastPositionInitialLoading, error: lastPositionInitialError, data: lastPositionInitial } = useQuery(Schema.GET_LAST_POSITION, { variables: { vehicleId: Number(vehicle.id)} })
  useEffect(() => {
    if(lastPositionInitial?.lastPosition){
      // console.log('vehicles - getLastPosition - result: ', result)
      dispatch.vehicles.setLastPosition(lastPositionInitial.lastPosition)
    }
  }, [lastPositionInitial])

  const { loading: anchorStatusInitialLoading, error: anchorStatusInitialError, data: anchorStatusInitial } = useQuery(Schema.GET_ANCHOR_STATUS, { variables: { vehicleId: Number(vehicle.id)} })
  useEffect(() => {
    if(anchorStatusInitial?.status){
      // console.log('vehicles - getLastPosition - result: ', result)
      dispatch.vehicles.setAnchorStatus(anchorStatusInitial.status)
    }
  }, [anchorStatusInitial])
  
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :(</p>;

  const { data: anchorChanged, loading: loadingAnchor } = useSubscription(
    Schema.ANCHOR_CHANGED_SUBSCRIPTION,
    { variables: { vehicleId: Number(vehicle.id) } }
  )
  useEffect(() => {
    if(anchorChanged?.anchorChanged){
      console.log('anchorChanged: ', anchorChanged)
      dispatch.vehicles.setAnchorStatus(anchorChanged.anchorChanged)
    }
  }, [anchorChanged])

  const { data: positionAdded, loading: loadingLastPositionSubscription } = useSubscription(
    Schema.POSITION_ADDED_SUBSCRIPTION,
    { variables: { vehicleId: Number(vehicle.id) } }
  )
  useEffect(() => {
    if(positionAdded?.positionAdded){
      console.log('positionAdded: ', positionAdded)
      dispatch.vehicles.setLastPosition(positionAdded.positionAdded)
    }
  }, [positionAdded])

  function _setVehicle(vehicle) {
    const { navigation, setVehicle } = props;
    navigation.closeRightDrawer();
    setVehicle(vehicle);
  }

  function checkStatus(datetime){
    const date = getDateFromIso(datetime)
    // console.log('date: ', date)
    const diff = diffNowHours(date)
    // console.log('diff: ', diff)
    let status = diff.toObject().hours <= 24 ? true : false
    // console.log('status: ', status)
    return status
  }

  function getDriver(position){
    if(position?.driver?.name){
      return position.driver.name
    }
    if(position?.driverId && position?.driverId != '-' && position?.driverId != 0){
      return `Motorista não cadastrado. Cartão Nº: ${position?.driverId}`
    }
    return null
  }

  function getBatteryLevelIcon(position){
    if(position?.power){
      const { power } = position
      if(power == 0)  return <Icon name='battery_level_0' size={icons.smaller}/>
      if(power <= 25) return <Icon name='battery_level_25' size={icons.smaller}/>
      if(power <= 50) return <Icon name='battery_level_50' size={icons.smaller}/>
      if(power <= 75) return <Icon name='battery_level_75' size={icons.smaller}/>
    }
    return <Icon name='battery_level_100' size={icons.smaller}/>
  }

  if(vehicle){
    return (
      <Container>
        <HeaderContainer>
          <CarContainer>
            {/* <Icon name='car' size={20} /> */}
            <Status online={checkStatus(lastPosition?.datetime)}>{index}</Status>
            <p
              // numberOfLines={2}
            >{vehicle.description}</p>
          </CarContainer>
          {lastPosition && 
            <>
              <AnchorContainer
                isAnchored={anchor?.status}
                onClick={() => dispatch.vehicles.toggleAnchorStatus(vehicle.id)}
              >
                <Icon name='anchor' size={20}/>
              </AnchorContainer>
              <GoToVehicleContainer onClick={() => _setVehicle(vehicle)}>
                <Icon name='arrow-up-right' size={20}/>
              </GoToVehicleContainer>
            </>
          }
        </HeaderContainer>
        {/* <a onClick={() => { navigation.navigate(`${screenName}`) }}> */}
          <BodyContainer>
            {lastPositionInitialLoading ? (
              <>
                <InfoLoader />
                <BadgesContainer>
                  <BadgeLoader />
                </BadgesContainer>
              </>
            ) : (
              <>
                {!lastPosition ? (
                  <EmptyPosition>Nenhuma posição encontrada.</EmptyPosition>
                ) : (
                  <>
                    <div>
                      <InfoContainer>
                        <Icon style={styles.icon} name='calendar' size={icons.big}/>
                        <p style={styles.text}>{formatDateTimeHuman(getDateFromIso(lastPosition?.datetime))}</p>
                      </InfoContainer>
                      {
                        lastPosition?.address &&
                        (
                          <InfoContainer>
                            <Icon style={styles.icon} name='map-marked' size={icons.small}/>
                            <p
                              style={styles.text}
                              // numberOfLines={2}
                            >
                              {lastPosition?.address.split('<br />').join(' - ')}
                            </p>
                          </InfoContainer>
                        )
                      }
                      {
                        getDriver(lastPosition) &&
                        (
                          <InfoContainer>
                            <Icon style={styles.icon} name='driver-license' size={icons.small}/>
                            <p style={styles.text}>{getDriver(lastPosition)}</p>
                          </InfoContainer>
                        )
                      }
                    </div>
                    <BadgesContainer>
                      <Badge
                        onOff
                        value={lastPosition?.ignition}
                        icon={<Icon name='key' size={icons.smaller}/>}
                      />
                      <Badge
                        icon={<Icon name='speedometer' size={icons.smaller} />}
                        text={lastPosition?.speed}
                        unit='Km/h'
                      />
                      <Badge
                        // icon={<Icon name='speedometer' size={icons.smaller}
                        text={lastPosition?.odometer}
                        unit='Km'
                      />
                      <Badge
                        text={lastPosition?.hourmeter}
                        unit='Horas'
                      />
                      <Badge
                        icon={<Icon name='temperature' size={icons.smaller}/>}
                        text={lastPosition?.temperature}
                        unit='°C'
                      />
                      <Badge
                        icon={<Icon name='battery' size={icons.smaller}/>}
                        text={lastPosition?.voltageP}
                        unit='V'
                      />
                      <Badge
                        icon={getBatteryLevelIcon(lastPosition)}
                        text={lastPosition?.power}
                        unit='%'
                      />
                      <Badge
                        onOff
                        value={Boolean(lastPosition?.inputDigital1)}
                        icon={<Icon name='input' size={icons.smaller}/>}
                        text='Entrada 1'
                      />
                      <Badge
                        onOff
                        value={Boolean(lastPosition?.inputDigital2)}
                        icon={<Icon name='input' size={icons.smaller}/>}
                        text='Entrada 2'
                      />
                      <Badge
                        onOff
                        value={Boolean(lastPosition?.outputDigital1)}
                        icon={<Icon name='output' size={icons.smaller}/>}
                        text='Saída 1'
                      />
                      <Badge
                        onOff
                        value={Boolean(lastPosition?.outputDigital2)}
                        icon={<Icon name='output' size={icons.smaller}/>}
                        text='Saída 2'
                      />
                    </BadgesContainer>
                  </>
                )}
              </>
            )}
          </BodyContainer>
        {/* </a> */}
      </Container>
    )
  }
}