import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  display: flex;
  /* flex: 1; */
  /* flex-direction: row; */

  a {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 10px;//normalize(10);
    margin-bottom: 1px;
    border-bottom-width: 1px;
    border-bottom-color: ${colors.darker};
    background-color: ${colors.darker};
    cursor: pointer;

    :hover{
      background-color: ${colors.menuItemHover};
    }
  }
`;

export const Icon = styled(IconComponent).attrs(props => ({
  size: "1.4em",
}))`
  /* margin-horizontal: 5px; */
  margin-right: 10px;
  color: ${colors.primary};
`

export const Title = styled.p`
  font-size: ${fonts.big};
  color: ${colors.white};
`
