export const Badge = (props) => {
  const { onOff, value, icon, text, unit } = props
  return (
    <>
      { (
          (typeof(text) !== 'undefined' && text !== null && typeof(text) !== '') ||
          (typeof(value) !== 'undefined' && value !== null && typeof(value) !== '')
        ) &&
        <Container {...props}>
          {icon}
          <Text {...props}>{text}</Text>
          {unit && <Unit {...props}>{unit}</Unit>}
        </Container>
      }
    </>
  )
}

import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #DDD;
  align-items: center;
  border-radius: 20px;
  padding: 5px 8px 5px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  ${props => props.onOff && (props.value ? `background-color: #00FF0066;` : `background-color: #FF000066;`)}
`;

const Text = styled.p`
  font-size: ${fonts.regular};
  ${props => props.icon && props.text ? `padding-left: 3px;`: ''}
`

const Unit = styled(Text)`
  font-size: ${fonts.regular};
  ${props => props.unit && props.text ? `padding-left: 3px;`: ''}
`