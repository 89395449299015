import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  /* border: blue 2px solid; */
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  cursor: pointer;  

  img {
    align-self:flex-start;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    transform: rotate(${props => props.rotate}deg);
    transform-origin: center center;
  }
`

export const TitleContainer = styled.p`
  margin-top: 4px;
  font-weight: bold;
  text-align: center;
  background-color: #444;
  border-radius: 4px;
  color: ${colors.white};
  padding: 5px;

  /* border: blue 2px solid; */
  white-space: nowrap;
`;

export const InfoDetails = styled.div`
  display: flex;
  flex-direction: row;
  p {
    font-weight: bold;
    margin-left: 4px;
  }
`;

export default {
  nameVehicleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // justifyContent: 'center',
    marginTop: 4,

    // borderColor: 'blue',
    // borderWidth: 1,
  },
  calloutTextContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontWeight: 'bold',
    // borderWidth: 1,
  },
  calloutText: {
    fontWeight: 'bold',
    // borderWidth: 1,
  },
};
