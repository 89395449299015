// import { APP_NAME } from "../../configs";

// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import history from "../routes/history";
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger'

const historyMiddleware = routerMiddleware(history);
const logger = createLogger({
  // ...options
});

let middlewares = [historyMiddleware]
const __DEV__ = true
if(__DEV__){
  middlewares.push(logger)
}

// import { bugsnag } from "./helpers/bugsnag";

// -----------
// Middlewares
// -----------

import { init } from '@rematch/core';
import * as models from './models';

const store = init({
  models,
  redux: {
    reducers: {
      router: connectRouter(history)
    },
    middlewares
  },
});

export { store };

//-------
// Models
//-------
// export const count = {
//   state: 0, // initial state
//   reducers: {
//       // handle state changes with pure functions
//       increment(state, payload) {
//           return state + payload
//       },
//   },
//   effects: dispatch => ({
//       // handle state changes with impure functions.
//       // use async/await for async actions
//       async incrementAsync(payload, rootState) {
//           await new Promise(resolve => setTimeout(resolve, 1000))
//           dispatch.count.increment(payload)
//       },
//   }),
// }
