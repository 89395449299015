import React, {Component} from 'react';
// import Icon from "react-native-vector-icons/Ionicons";
import {Container, Input} from "./styles";
import { icons } from '~/styles';
import { Icon } from '~/components/icon';
import { useDispatch, useSelector } from 'react-redux';
// import { actionCreators as acPositions } from '~/services/redux/positions';
// import { TouchableOpacity } from 'react-native-gesture-handler';

export const SearchBar = (props) => {
  const { searchingFor } = useSelector(state => state.vehicles.vehicles);

  const dispatch = useDispatch()

  function onChange(e){
    dispatch.vehicles.setSearchPosition(e.target.value)
    console.log('searchingFor: ', e.target.value)
  }

  function clearSearch(){
    dispatch.vehicles.clearSearchPosition()
  }

  return (
    <Container>
      <Icon name='search' size={icons.regular}/>
      <Input>
        <input
          placeholder='Pesquisar por veículo, motorista ou endereço...'
          value={searchingFor}
          onChange={onChange}
        />
      </Input>
      {
        searchingFor?.length > 0 &&
        (
          <a onClick={clearSearch}>
            <Icon name='close' size={icons.big}/>
          </a>
        )
      }
    </Container>
  )
}