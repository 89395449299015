import React, { useRef, useState } from "react";

import logo from '~/assets/img/logo/logo.svg';

import { Container, Logo, MenuToggler, VehiclesToggler } from "./styles";
import { Icon } from "~/components/icon";
import { useDispatch, useSelector } from "react-redux";

export default function HeaderTop() {
  const dispatch = useDispatch();

  // console.log('header.leftMenu', header.leftMenu);
  // console.log('header.rightMenu', header.rightMenu);

  return (
    <Container>
      <MenuToggler >
        <div onClick={() => dispatch.ui.toggleHeaderMenuLeft()}>
          <Icon name='menu' />
        </div>
      </MenuToggler>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      <VehiclesToggler>
        <div onClick={() => dispatch.ui.toggleHeaderMenuRight()}>
          <Icon name='car' size={35} />
        </div>
      </VehiclesToggler>
    </Container>
  );
}
