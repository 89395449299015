import React, {Component} from 'react';
import { Container } from "./styles";
import { icons, colors } from '~/styles';
import { Badge } from './Badge';
import { Icon } from '~/components/icon';
import { useSelector } from 'react-redux';
// import Icon from "react-native-vector-icons/Ionicons";
// import IconFA5 from "react-native-vector-icons/FontAwesome5";
// import IconAD from "react-native-vector-icons/AntDesign";

// import { actionCreators as acPositions } from '~/services/redux/positions';
// import { TouchableOpacity } from 'react-native-gesture-handler';

export const OrderByBar = (props) => {
  const { field, order, loading } = useSelector(state => state.vehicles.vehicles.orderBy);
  // console.log('OrderByBar - render - orderBy: ', props.orderBy);

  return (
    // <ScrollView horizontal>
    <Container>
      <Badge
        icon={<Icon name='car' size={icons.smaller}/>}
        field='vehicle'
        selected={field}
        text='Veículo'
      />
      <Badge
        icon={<Icon name='calendar' size={icons.smaller}/>}
        field='datetime'
        selected={field}
        text='Data/Hora'
      />
      <Badge
        icon={<Icon name='key' size={icons.smaller}/>}
        field='ignition'
        selected={field}
        text='Ignição'
      />
    </Container>
    // </ScrollView>
  )
}