import ContentLoader, { BulletList, Facebook } from 'react-content-loader'

export const VehicleLoader = () => (
  <ContentLoader
    viewBox="0 0 380 70"
    speed={1.5}
  >
    {/* Only SVG shapes */}    
    <rect x="0" y="0"  width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>
)

export const ProfileLoader = () => (
  <ContentLoader
    height={56}
    // speed={1.5}
    // backgroundColor={'#333'}
    foregroundColor={colors.gray5}
    viewBox="0 0 300 56"
  >
    {/* Only SVG shapes */}
    <rect x="0" y="0" rx="50%" ry="50%" width="56" height="56" />
    <rect x="66" y="10" rx="4" ry="4" width="200" height="14" />
    <rect x="66" y="33" rx="3" ry="3" width="160" height="10" />
  </ContentLoader>
)

export const InfoUnitLoader = () => (
  <ContentLoader
    // height={140}
    // speed={1}
    // backgroundColor={'#333'}
    // foregroundColor={'#999'}
    viewBox="0 0 380 30"
  >
    {/* Only SVG shapes */}
    <rect x="0" y="0" rx="50%" ry="50%" width="20" height="20" />
    <rect x="40" y="0" rx="4" ry="4" width="350" height="20" />
  </ContentLoader>
)

export const InfoLoader = () => (
  <Container>
    <InfoUnitLoader />
    <InfoUnitLoader />
    <InfoUnitLoader />
  </Container>
)

export const BadgeUnitLoader = () => (
  <BadgeUnitContainer>
    <ContentLoader viewBox="0 0 81 27">
      <rect x="0" y="0" rx="8" ry="8" width="76" height="22" />
    </ContentLoader>
  </BadgeUnitContainer>
)

export const BadgeLoader = () => (
  <>
    {/* Only SVG shapes */}    
    <BadgeUnitLoader />
    <BadgeUnitLoader />
    <BadgeUnitLoader />
    <BadgeUnitLoader />
    <BadgeUnitLoader />
    <BadgeUnitLoader />
  </>
)

import styled from "styled-components";
import { colors } from '~/styles'
// import { Icon as IconComponent } from "~/components/icon";
// import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.div`
  /* border: blue 2px solid; */
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  /* align-items: center; */
`

export const BadgeUnitContainer = styled.div`
  width: 76px;
`

// export const ProfileContainer = styled.div`
//   width: 76px;
// `