import React, { useState } from 'react';
import { icons } from '~/styles';
import { Container, Icon } from './styles';

export const Alert = (props) => {
  const { children } = props

  const [show, setShow] = useState(true);
  
  function handleOnClose(e) {
    // console.log('Alert - handleOnClose')
    setShow(false)
  }

  if(show){
    return (
      <Container {...props}>
        <label>{children}</label>
        <Icon name='close' size={icons.xb} onClick={handleOnClose} />
      </Container>
    )
  }
  return null
}