import { DateTime } from "luxon";
// import 'moment/locale/pt-br';
// import 'moment-duration-format';
// import { LANGUAGE, DATE_FORMAT } from "../../configs";

/**
 * Helper to manipulate date
 **/

/**
 * @param {any} date
 * @returns
 */
// export const formatDate = (date, format) => {
//   moment.locale(LANGUAGE);
//   return moment(date).format(format);
// }

// export const formatDateSQL = (date) => {
//   return formatDate(date, DATE_FORMAT.SQL.DATE);
// }

// export const formatDateTimeSQL = (date) => {
//   return formatDate(date, DATE_FORMAT.SQL.DATETIME);
// }

// export const formatDateTimeFile = (date) => {
//   return formatDate(date, DATE_FORMAT.FILE.DATETIME);
// }

// export const formatDateHuman = (date) => {
//   return formatDate(date, DATE_FORMAT.HUMAN.DATE);
// }

export const formatDateTimeHuman = (date) => {
  return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}

// export const nowIsSameOrBeforeThan = (date) => {
//   return moment().isSameOrBefore(moment(date));
// }

// export const fullDayFormat = (date) => {
//   return formatDate(date, 'D [de] MMMM [de] YYYY');
// }

// export const monthFormat = (date) => {
//   return formatDate(date, 'MMMM');
// }

// export const yearFormat = (date) => {
//   return formatDate(date, 'YYYY');
// }

// export const secondsFormat = (seconds) => {
//   return moment.duration(seconds, 'seconds').format('hh:mm:ss', {trim: false});
// }

export const now = () => {
  return DateTime.now()
}

export const nowUTC = () => {
  return DateTime.fromObject({zone: 'UTC'});
}

export const getDateFromIso = (date) => {
  return DateTime.fromISO(date);
}

// export const getDate = (date) => {
//   return moment(date);
// }

// export const diffNow = (timestamp) => {
//   return noww().diff(timestamp, 'seconds');
// }

export const diffNowHours = (timestamp) => {
  return nowUTC().diff(timestamp, 'hours');
}

// export const diffInYears = (timestamp) => {
//   return noww().diff(timestamp, 'years');
// }
