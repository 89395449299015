import React, { useContext, useEffect, useState } from 'react';

import '~/styles/css/reset.css'
import '~/styles/css/normalize.css'
import '~/styles/css/global.css'

// GraphQL
import { ApolloProvider } from '@apollo/client';
import { gqlClient as client } from "~/services/graphql/apollo";

// Redux
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from '~/services/store';

import Routes from '~/services/routes';

// Others
// import { runMigrationsRealm, getRealm, closeRealm } from './services/database';
// import { init as initBugsnag } from '~/services/helpers/bugsnag';
// import { configure as configureNotification, unmount as unmountNotification } from './services/helpers/onesignal';
// import { setDefaults } from './services/helpers/remoteConfig';

// import { configure as configureOneSignal, unmount as unmountOneSignal } from './services/helpers/onesignal';

const App = () => {
  // const dispatch = useDispatch()
  // const header = useSelector(state => state.ui.header);

  useEffect(() => {
    // configureNotification();
    // runMigrationsRealm();
    // getRealm();
    // initBugsnag();
    // setDefaults();

    return function cleanup() {
      // unmountNotification();
      // closeRealm();
    };
  }, [])

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ApolloProvider>
  );
};

export default App;