import { createContext, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Menu from 'react-burger-menu/lib/menus/slide'
import { colors } from "~/styles";
import { styleMenu } from "./styles";
import { LeftMenu } from "./Left";
import { RightMenu } from "./Right";

export const Sidebar = ({left, right}) => {
  const dispatch = useDispatch()
  const header = useSelector(state => state.ui.header);

  if(left){
    return (
      <SidebarProvider
        isOpen={header.leftMenu}
        toggleMenu={() => dispatch.ui.toggleHeaderMenuLeft()}
        setMenu={(newState) => dispatch.ui.setHeaderMenuLeft(newState.isOpen)}
      >
        <div>
          {/* <Button /> */}
          <Navigation styles={styleMenu({backgroundColor: colors.darker})}>
            <LeftMenu />
          </Navigation>
        </div>
      </SidebarProvider>
    );
  }

  if(right){
    return (
      <SidebarProvider
        isOpen={header.rightMenu}
        toggleMenu={() => dispatch.ui.toggleHeaderMenuRight()}
        setMenu={(newState) => dispatch.ui.setHeaderMenuRight(newState.isOpen)}
      >
        <div>
          {/* <Button /> */}
          <Navigation right
            // width={window.innerWidth > 768 ? '30%' : null}
            styles={styleMenu({backgroundColor: colors.white, closeButton: false})}
          >
            <RightMenu />
          </Navigation>
        </div>
      </SidebarProvider>
    );
  }

  return null
}

  // make a new context
const MyContext = createContext();

// create the provider
export const SidebarProvider = ({children, isOpen, toggleMenu, setMenu}) => {
  // const [menuOpenState, setMenuOpenState] = useState(false)
  
  return (
    <MyContext.Provider value={{
      isMenuOpen: isOpen, //menuOpenState,
      toggleMenu, //setMenuOpenState(!menuOpenState),
      stateChangeHandler: setMenu,
    }}>
      {children}
    </MyContext.Provider>
  )
}

// // create a button that calls a context function to set a new open state when clicked
// const Button = () => {
//   const ctx = useContext(MyContext)
//   return (
//     <button onClick={ctx.toggleMenu}>Toggle menu</button>
//   )
// }

// create a navigation component that wraps the burger menu
const Navigation = ({children, right, width, styles = {}}) => {
  const ctx = useContext(MyContext)

  return (
    <Menu
      // noOverlay
      right={right}
      width={width}
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      styles={styles}
    >
      {children}
    </Menu>
  )
}