import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import history from "./history";

import { Login } from "~/pages/Shared/Login";
import { Home as ClientHome } from "~/pages/Client/Home";
import { isAuthenticated } from "~/services/store/models/session";
import { useDispatch, useSelector } from "react-redux";
// import AdminHome from "~/pages/Admin/Home";
// import Relatorios from "~/pages/Shared/Relatorios";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  console.warn('PrivateRoute - isAuthenticated: ', isAuthenticated)
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )}
    />
  )
}

const Routes = () => {
  const dispatch = useDispatch()
  const {loggedIn} = useSelector(state => state.session.session)

  async function checkAuthenticate(){
    let authenticated = await isAuthenticated()
    console.warn('Routes - authenticated: ', authenticated)
    dispatch.session.setLoggedIn(authenticated)
    if(authenticated){
      history.push('/')
    }
  }
  useEffect(() => {
    checkAuthenticate()
  }, [loggedIn])

  return(
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        {/* <Route path="/register" component={() => <h1>SignUp</h1>} /> */}
        <PrivateRoute path="/" exact component={ClientHome} isAuthenticated={loggedIn}/>
        {/* <PrivateRoute path="/relatorios" exact component={Relatorios} /> */}
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </ConnectedRouter>
  )
}

export default Routes;
