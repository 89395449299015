export const ui = {
  state: {
    header: {
      dropdown: false,
      searchBox: false,
      leftMenu: false,
      rightMenu: false,
    },
  },
  reducers: {
    toggleHeaderDropdown: (state, payload) => ({...state, header: { ...state.header, dropdown: !state.header.dropdown} }),
    toggleHeaderSearchBox: (state, payload) => ({...state, header: { ...state.header, searchBox: !state.header.searchBox} }),
    toggleHeaderMenuLeft: (state, payload) => ({...state, header: { ...state.header, leftMenu: !state.header.leftMenu} }),
    toggleHeaderMenuRight: (state, payload) => ({...state, header: { ...state.header, rightMenu: !state.header.rightMenu} }),
    setHeaderDropdown: (state, payload) => ({...state, header: { ...state.header, dropdown: payload} }),
    setHeaderSearchBox: (state, payload) => ({...state, header: { ...state.header, searchBox: payload} }),
    setHeaderMenuLeft: (state, payload) => ({...state, header: { ...state.header, leftMenu: payload} }),
    setHeaderMenuRight: (state, payload) => ({...state, header: { ...state.header, rightMenu: payload} }),
    clearUi: (state, payload) => ({...ui.state}),
  },
  effects: dispatch => ({
    
  }),
}
