import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from "~/configs"

export const Maps = ({
  children,
  zoom = 5,
  center = {
    lat: -14.540694,
    lng: -53.033778,
  },
}) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {children}
      </GoogleMapReact>
    </div>
  )
}