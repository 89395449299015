import React from 'react';
import {Container, Tooltip} from './styles';

export const Status = (props) => {
  const { children, online } = props

  return (
    <Container
      {...props}
      data-toggle="tooltip" 
      // data-placement="left"
      title={online ? 'Status: Online' : 'Status: Offline'}>
      <span>{children}</span>
      {/* <Tooltip>{online ? 'Status: Online' : 'Status: Offline'}</Tooltip> */}
    </Container>
  )
}