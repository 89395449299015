export default {
  primary: "#ff401e",
  primaryLight: "#d71e04",

  secondaryDark: "#eca94e",
  secondaryLight: "#F07043",
  tertiary: "#3d76a3",

  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#C4C4C4",
  gray6: "#E0E0E0",
  gray7: "#F2F2F2",

  black: '#000',
  darker: "#111",
  dark: "#333",
  regular: "#666",
  light: "#C0C0C0",
  lighter: "#eee",
  white: "#FFF",

  menuItemHover: '#373a47',

  red: "#FF0000",
  redLight: "#FF000022",
  green: "#00FF00",
  greenLight: "#00FF0022",
  blue: "#0000FF",
  blueLight: "#0000FF22",
  blueLink: '#2F80ED',
  yellowLight: "#fff3cd",

  transparent: "rgba(0,0,0,0)"
};
