import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

export const Container = styled.button`
  /* display: flex; */
  height: 40px;
  background-color: ${props => props.secundary ? colors.white : colors.primary};
  /* background-color: #d63914; */
  color: ${props => props.secundary ? colors.primary : colors.white};
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-transform: uppercase;
  /* border-radius: 5px; */
  border-radius: 2px;
  overflow: hidden;
  font-weight: bold;
  font-size: ${fonts.big};
  box-shadow: 0 1px 3px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.18);
  outline: none;
  border-color: ${props => props.secundary ? colors.primary : 'transparent'};
  border: 1px solid ${props => props.secundary ? colors.primary : 'transparent'};
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;

  :hover {
    box-shadow: 0 3px 6px rgba(0,0,0,.2), 0 3px 6px rgba(0,0,0,.26);
    padding: 10px 20px!important;
    background-color: #d63914;
    border-color: #ac5d03;
    color: ${colors.white};
    text-decoration: none;
    border: 1px solid transparent;
  }
`
