const saveObject = async (schemaName, object, update = false) => {
  console.log('database - saveObject - schemaName: ', schemaName, object, typeof object);
  if(object){
    let newObject = isObject(object) ? JSON.stringify(object) : object;
    localStorage.setItem(schemaName, newObject);
  }
}

// const saveObjects = (schemaName, newObjects, update = false) => {
//   for (let object of newObjects) {
//     await saveObject(schemaName, object, update);
//   }
// }

const getObject = async (schemaName) => {
  console.log('database - getObject - schemaName: ', schemaName);
  const tempObject = localStorage.getItem(schemaName)
  // console.log('database - getObject - tempObject: ', tempObject, typeof tempObject);
  let newObject = null
  if(tempObject){
    newObject = JSON.parse(tempObject);
    newObject = isObject(newObject) ? newObject : tempObject;
  }
  return newObject;
}

// const getObjects = (schemaName) => {
//   const realm = await getRealm();
//   console.log('database - getObjects: ', schemaName);
//   return realm.objects(schemaName);
// }

const deleteObject = async (schemaName) => {
  console.log('database - deleteObject - schemaName: ', schemaName);
  localStorage.removeItem(schemaName);
}

const deleteAllObjects = async (schemaName) => {
  await deleteObject(schemaName);
}

const deleteAllAndSaveObject = async (schemaName, object) => {
  await saveObject(schemaName, object);
}

// const deleteAllAndSaveObjects = (schemaName, newObjects) => {
//   let objects = await getObjects(schemaName);
//   await deleteObject(objects);
//   for (let object of newObjects) {
//     await saveObject(schemaName, object);
//   }
// }

const isObject = (value) => {
  return value !== null && typeof value === 'object'
}

export const Database = {
  saveObject,
  // saveObjects,
  getObject,
  // getObjects,
  deleteObject,
  deleteAllObjects,
  deleteAllAndSaveObject,
  // deleteAllAndSaveObjects,
};
