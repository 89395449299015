import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: ${metrics.padding}px;
  // backgroundColor: colors.primary;
  flex-direction: row;
  // borderWidth: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  // marginLeft: 10;
`;

const styles = {
  textMain: {
    alignSelf: 'center',
    fontSize: fonts.big,
  },
};

export default styles;
