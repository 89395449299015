import apiInstance from "./index";
import { CLIENT } from "../../configs";

const prefix = 'api/v1/';

export const routes = {
  session: {
    login: {
      method: 'post',
      url: `oauth/token`,
      data: ({email, password}) => ({
        grant_type: "password",
        client_id: CLIENT.ID,
        client_secret: CLIENT.SECRET,
        username: email,
        password,
        scope: "",
      })
    },
    refreshToken: {
      method: 'post',
      url: `${prefix}oauth/token`,
      data: ({refresh_token}) => ({
        grant_type: "refresh_token",
        refresh_token: refresh_token,
        client_id: CLIENT.ID,
        client_secret: CLIENT.SECRET,
        scope: "",
      })
    },
    getUser: {
      method: 'get',
      url: `${prefix}user`,
    },
    addDeviceOneSignal: {
      method: 'post',
      url: `${prefix}onesignal`,
    },
    removeDeviceOneSignal: {
      method: 'delete',
      url: `${prefix}onesignal`,
    },
    logout: {
      method: 'post',
      url: `${prefix}logout`,
    },
  },
  
  changePassword: {
    method: 'put',
    url: `${prefix}user/password`,
  },
  recoveryPassword: {
    method: 'post',
    url: `${prefix}user/password/recovery`,
  },
  
  // submitImageSupport: {
  //   method: 'post',
  //   url: `${prefix}support/image`,
  // },
  submitSupport: {
    method: 'post',
    url: `${prefix}support`,
  },
  sendContact: {
    method: 'post',
    url: `${prefix}contact`,
    data: ({ nome, telefone, email, ramoAtividade, necessidades }) => ({
      nome: nome,
      telefone: telefone,
      email: email,
      ramoAtividade: ramoAtividade,
      necessidades: necessidades,
    }),
  },
  getPlans: {
    method: 'get',
    url: `${prefix}plans`,
  },
  validateUserSubscription: {
    method: 'post',
    url: `${prefix}validate`,
  },
  submitUser: {
    method: 'post',
    url: `${prefix}register`,
  },
  submitSubscription: {
    method: 'post',
    url: `${prefix}subscriptions`,
  },
  updateCreditCard: {
    method: 'post',
    url: `${prefix}subscriptions/creditCard?_method=PUT`,
  },
  cancelSubscription: {
    method: 'post',
    url: `${prefix}subscriptions?_method=DELETE`,
  },
  getMentorings: {
    method: 'get',
    url: `${prefix}mentorings`,
  },
  sendMessageMentorings: {
    method: 'post',
    url: `${prefix}mentorings/messages`,
  },
  getPatients: {
    method: 'get',
    url: `${prefix}patients`,
  },
  getPatientProfile: {
    method: 'get',
    url: `${prefix}patients/profile`,
  },
  submitPatient: {
    method: 'post',
    url: `${prefix}patients`,
    // data: (params) => ({
    //   ...params,
    //   // birth: getDateUtcHumanFormat(params.birth)
    // }),
  },
  getScreenings: {
    method: 'get',
    url: `${prefix}screenings`,
  },
  getAssassements: {
    method: 'get',
    url: `${prefix}nutritionals`,
  },
  submitNutritionalAssessment: {
    method: 'post',
    url: `${prefix}nutritionals`,
    // data: (params) => ({
    //   ...params,
    //  // created_at: getDatetimeUtcHumanFormat(params.created_at)
    // }),
  },
  submitMonitoring: {
    method: 'post',
    url: `${prefix}monitorings`,
    // data: (params) => ({
    //   ...params,
    //   // created_at: getDatetimeUtcHumanFormat(params.created_at)
    // }),
  },
  getDryWeights: {
    method: 'get',
    url: `${prefix}dryWeights`,
  },
  getAmputatedLimbs: {
    method: 'get',
    url: `${prefix}amputatedLimbs`,
  },
  getDiseases: {
    method: 'get',
    url: `${prefix}diseases`,
  },
  getDashboard: {
    method: 'get',
    url: `${prefix}dashboard`,
  },
  uploadFile: {
    method: 'post',
    url: `${prefix}file`,
  },
  downloadFile: {
    method: 'get',
    url: `${prefix}file`,
  },
  deleteFile: {
    method: 'post',
    url: `${prefix}file`,
    data: (params) => ({
      ...params,
      _method: 'DELETE',
    }),
  },
};

/**
 * API Calls
 */

export const apiSendContact = async ({ nome, telefone, email, ramoAtividade, necessidades }) => {
  const response = await api.post(prefix + 'contact', {
    nome: nome,
    telefone: telefone,
    email: email,
    ramoAtividade: ramoAtividade,
    necessidades: necessidades,
  });
  // console.log('api - apiSendContact - response: ', response);
  return response;
}

export const apiGetLastPositions = async (user) => {
  const response = await api.get(prefix + 'veiculos/positions/last', {
    orderBy: '',
  });
  // console.log('api - apiGetVeiculos - response: ', response);
  return response;
}

export const apiSetStatusAnchor = async (params) => {
  const response = await api.post(prefix + 'anchor/status', params);
  // console.log('api - apiSendEvent - response: ', response);
  return response;
}

export const apiSetStatusSos = async (params) => {
  const response = await api.post(prefix + 'sos/status', params);
  // console.log('api - apiSendEvent - response: ', response);
  return response;
}

export const apiSendCurrentPositionSos = async (params) => {
  const response = await api.post(prefix + 'sos/position/current', params);
  // console.log('api - apiSendEvent - response: ', response);
  return response;
}

export const apiGetVehiclesNearSos = async (user) => {
  const response = await api.get(prefix + 'sos/positions/vehicles', {});
  // console.log('api - apiGetVeiculos - response: ', response);
  return response;
}

export const apiCheckSos = async (user) => {
  const response = await api.get(prefix + 'sos/check', {});
  // console.log('api - apiGetVeiculos - response: ', response);
  return response;
}

export const apiAsync = async (route) => {
  // console.warn('apiAsync - route: ', route)
  const response = await apiInstance(route);
  console.log('response: ', response);
  return response;
};

export const api = (route) => {
  // console.warn('api - route: ', route)
  return apiInstance(route);
};