import styled from "styled-components";
import { Icon as IconComponent } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";
// import { Header3, Header4 } from "~/styles/global";

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip

export const Container = styled.button`
  /* display: flex; */
  /* height: 40px; */
  ${props => (props.online ? `background-color: #00FF0088;` : `background-color: #FF000088;`)}
  /* background-color: #d63914; */
  color: ${props => props.secundary ? colors.primary : colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* padding: 10px 10px; */
  text-transform: uppercase;
  /* border-radius: 5px; */
  overflow: hidden;
  font-weight: bold;
  font-size: ${fonts.big};
  box-shadow: 0 3px 6px rgba(0,0,0,.2), 0 3px 6px rgba(0,0,0,.26);
  outline: none;
  border-color: ${props => (props.online ? '#00FF0088' : '#FF000088')};
  border: 0px solid ${props => (props.online ? '#00FF0088' : '#FF000088')};
  /* cursor: pointer; */
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;

  span {
    font-size: ${fonts.small}
  }

  /* :hover {
     {
      visibility: visible;
      opacity: 1;
    }
  } */
`

// export const Tooltip = styled.span`
//   visibility: visible;
//   opacity: 1;
//   /* visibility: hidden; */
//   /* opacity: 0; */
//   width: 120px;
//   background-color: #555;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 0;
//   position: absolute;
//   z-index: 1;
//   bottom: 125%;
//   left: 50%;
//   margin-left: -60px;
//   transition: opacity 0.3s;

//   ::after {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: #555 transparent transparent transparent;
//   }
// `