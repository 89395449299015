import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

export const Badge = (props) => {
  const { icon, text, field, selected, onClick } = props

  const dispatch = useDispatch()

  const [isSelected, setIsSelected] = useState(false)
  const [order, setOrder] = useState('asc')

  useEffect(() => {
    setIsSelected(field === selected ? true : false)
    if(field !== selected){
      setOrder('asc')
    }
  }, [selected])

  function changeOrder() {
    if(isSelected === false){
      setOrder('asc')
      dispatch.vehicles.setOrderByPositions({field, order})
    } else {
      let orderTemp = order === 'asc' ? 'desc' : 'asc'
      setOrder(orderTemp)
      dispatch.vehicles.setOrderByPositions({field, order: orderTemp})
    }
  }

  return (
    <Container
      {...props}
      onClick={() => {
        changeOrder()
      }}
    >
      <IconContainer>{icon}</IconContainer>
      <Text>{text}</Text>
      { isSelected && order === 'asc' && (<Icon name='caret-up' size={icons.smaller}/>)}
      { isSelected && order === 'desc' && (<Icon name='caret-down' size={icons.smaller}/>)}
      {/* { isSelected && loading && (<ActivityIndicator style={{marginLeft: 5}} color={colors.dark} size={icons.smaller}/>)} */}
    </Container>
  )
}

import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize, icons } from "~/styles";

const Container = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  border-radius: 20px;
  border-width: 1px;
  padding: 5px 8px 5px 8px;
  margin-right: 5px;
  cursor: pointer;

  ${props => props.field === props.selected ? `
    background-color: ${colors.primary};
    border-color: ${colors.primary};
  ` : `
    background-color: ${colors.gray6};
    border-color: #DDD;
  `}
`;

const IconContainer = styled.div`
  margin-right: 5px;
`

const Text = styled.p`
  font-size: ${fonts.big};
  margin-right: 5px;
  /* font-size: ${fonts.regular};
  ${props => props.icon && props.text?.length > 0 ? `padding-left: 5px;`: ''} */
`