
import styled from "styled-components";
import { Icon } from "~/components/icon";
import { colors, metrics, fonts, normalize } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  margin: ${metrics.padding}px;
  padding: ${metrics.padding}px;
  border-radius: 30px;
  border: gray 1px solid;
  font-weight: bold;
  // backgroundColor: colors.primary;
  cursor: pointer;
`;

export const Input = styled.div`
  display: flex;
  flex: 1;
  /* margin-left: 5px; */
  // borderColor: 'blue';
  // borderWidth: 1;

  input {
    width: 100%;
    border-width: 0px;
    font-size: ${fonts.big};
    color: ${colors.darker};
    :focus {
      outline: none;
    }
  }
`
