import React, {Component} from 'react';
// import Icon from "react-native-vector-icons/Ionicons";
import styles, { Container, Icon, Title } from "./styles";
import { normalize, icons } from '~/styles';

export const MenuItem = ({ onClick, title, icon }) => {
  return (
    <Container>
      <a onClick={onClick}>
        <Icon name={icon} size={icons.regular} />
        <Title>{title}</Title>
      </a>
    </Container>
  );
}