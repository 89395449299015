import React, { Fragment, useContext, useEffect, useState } from 'react';

import Header from '~/components/layout/Header';
import { Maps } from '~/components/maps';
import Marker from '~/components/maps/marker';
import { Sidebar } from '~/components/layout/Sidebar';
import { useDispatch, useSelector } from 'react-redux';

export const Home = () => {
  const dispatch = useDispatch()
  const {list, lastPositions} = useSelector(state => state.vehicles.vehicles)
  const vehicles = list

  useEffect(() => {
    console.log('Home - getVehicles')
    dispatch.vehicles.getVehicles()
  }, [])

  // console.log('Home - lastPositions: ', lastPositions)

  return(
    <div style={{ display:'flex', flexDirection:'column', height: '100vh' }}>
      <Header />

      <Sidebar left />
      <Sidebar right />

      <Maps>
        { vehicles.map((e,i,a) => {
          if(lastPositions && Object.keys(lastPositions).length > 0 && lastPositions[e.id]) {
            const lastPosition = lastPositions[e.id]
            return(
              <Marker
                key={e.id}
                type={1}
                ignition={lastPosition.ignition}
                lat={lastPosition.latitude}
                lng={lastPosition.longitude}
                rotate={lastPosition.course}
                index={i}
                vehicle={e}
                lastPosition={lastPosition
                // {
                //   'veiculo': {
                //     'id': 123,
                //     'descricao': 'TESTE',
                //     'placa': 'ABC1234',
                //   },
                //   'datetime': {
                //       'raw': '2021-02-18 14:24:00',
                //       'formatted': '18/02/2021 14:24:00',
                //   },
                //   'endereco': 'Rua João Batista',
                //   'motorista': 'João Maria',
                //   'latitude': -6.064449,
                //   'longitude': -35.440584,
                //   'ignicao': 1,
                //   'velocidade': {
                //       'raw': 32,
                //       'formatted': '32 Km/h',
                //   },
                //   'rotacao': 135,
                //   'odometro': 1000,
                //   'hodometro': 2000,
                //   'horimetro': 3000,
                //   'temperatura': {
                //       'raw': 36,
                //       'formatted': '36 ºC',
                //   },
                //   'voltagem': 12.4,
                //   'input1': 1,
                //   'input2': 0,
                // }
                }
              />
            )
          }
          return null
        })}
      </Maps>
    </div>
  )
}