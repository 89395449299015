import React, { useState } from "react";

import logoFile from '~/assets/img/logo/logo.svg';

import { useSelector, useDispatch } from "react-redux";
import { Container, Copyright, FormContainer, Header, InputsContainer, Logo, FormActions, SubHeader } from "./styles";
import { colors } from "~/styles";
import { Header3 } from "~/styles/global";
import { Button } from "~/components/button";
import { Checkbox } from "~/components/checkbox";
import { Link } from "~/components/link";
import { Alert } from "~/components/alert";
import { now } from "~/services/helpers/date";

export const Login = () => {
  const dispatch = useDispatch();
  const {hasError, errors, loading, email, password, rememberMe} = useSelector(state => state.session.login);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  // console.log(errorMessage?.data?.message)

  function handleShowForgotPassword(e) {
    console.log(showForgotPassword);
    setShowForgotPassword(!showForgotPassword); 
  }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log('dispatch login')
    await dispatch.session.loginUser();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }
  
  return (
    <Container>
      <Logo>
        <img src={logoFile} alt="Rastreauto" />
      </Logo>
      <FormContainer>
        {!showForgotPassword ? (
          <>
            <Header>
              <Header3 color={colors.primary}>Login</Header3>
            </Header>
            {hasError && 
              <Alert danger>
                { errors.graphQLErrors.map(({ message }, i) => (
                  <span key={i}>{message.replace('Argument Validation Error', 'Preencha os campos corretamente')}</span>
                ))}
              </Alert>
            }
            <InputsContainer>
              <input value={email} onChange={(e) => dispatch.session.setEmail( e.target.value)} type="text" autoComplete="off" placeholder="Usuário" name="USR_LGN" required autoFocus />
              <input value={password} onChange={(e) => dispatch.session.setPassword(e.target.value)} onKeyDown={handleKeyDown} type="password" autoComplete="off" placeholder="Senha" name="USR_PWD" required />
            </InputsContainer>
            <FormActions>
              <Button onClick={handleSubmit} loading={loading}>Entrar</Button>
              <Checkbox name="remember" value={rememberMe} onClick={() => dispatch.session.setRememberMe(!rememberMe)}>Lembrar</Checkbox>
              <Link onClick={handleShowForgotPassword}>Esqueceu sua senha?</Link>
            </FormActions>
          </>
        ) : (
          <>
            <Header>
              <Header3 color={colors.primary}>Esqueceu sua senha?</Header3>
            </Header>
            <SubHeader> Digite seu e-mail abaixo para redefinir sua senha.</SubHeader>
            <InputsContainer>
              <input className="form-control placeholder-no-fix" type="text" autoComplete="off" placeholder="E-mail" name="email" />
            </InputsContainer>
            <FormActions>
              <Button onClick={handleShowForgotPassword} secundary>Voltar</Button>
              <Button type="submit">Enviar</Button>
            </FormActions>
          </>
        )}
      </FormContainer>
      <Copyright className="copyright"> {now().year} &copy; Rastreauto.</Copyright>
    </Container>
  );
}