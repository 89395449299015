import React, { useState } from 'react';
// import {
//   div,
//   span,
//   Image,
//   TouchableOpacity,
//   Dimensions,
// } from 'react-native';
import styles, {Container, TitleContainer,InfoDetails} from "./styles";
// import { colors, metrics, fonts, icons, normalize } from "../../styles";

// import { Marker, Callout } from 'react-native-maps';

import CarOff from '~/assets/img/markers/car-off.svg';
import CarOn from '~/assets/img/markers/car-on.svg';
import Callout from './callout';
import { formatDateTimeHuman, getDateFromIso } from '~/services/helpers/date';
// import CarOcorrenciaOff from './svg/CarOcorrenciaOff';
// import CarOcorrenciaOn from './svg/CarOcorrenciaOn';


const markerTypes = {
  1: {
    false: CarOff,
    true: CarOn,
  }
};

export default function Marker(props) {
  const {type = 1, ignition = false, index, vehicle, lastPosition} = props
  // console.log('CustomMarker: ', lastPosition);
  const [showCallout, setShowCallout] = useState(false);
  // console.log('showCallout: ', showCallout);

  return (
    <Container
      {...props}
      onClick={() => setShowCallout(!showCallout)}
    >
      <Callout
        latitude={lastPosition.latitude}
        longitude={lastPosition.longitude}
        item={lastPosition}
        isVisible={showCallout}
        zIndex={index}
        onClickToClose={() => setShowCallout(false)}
      >
        <div>
          {/* {/* style="color:#000" */}
          <InfoDetails>Veículo: <p>{vehicle.description}</p></InfoDetails>
          <InfoDetails>Data/hora: <p>{formatDateTimeHuman(getDateFromIso(lastPosition.datetime))}</p></InfoDetails>
          <InfoDetails>Endereço: <p>{lastPosition.address ? lastPosition.address?.replace('<br />', ' - ') : ' - '}</p></InfoDetails>
          <InfoDetails>Velocidade: <p>{lastPosition.speed} Km/h</p></InfoDetails>
          {/* <InfoDetails>Ignição: <p>{lastPosition.ignicao}</p></InfoDetails> */}
          <InfoDetails>Hodômetro: <p>{lastPosition.odometer}</p></InfoDetails>
          <InfoDetails>Horímetro: <p>{lastPosition.hourmeter}</p></InfoDetails><br/>
          [ <a href={`https://www.google.com/maps/?q=${lastPosition.latitude},${lastPosition.longitude}`} target="_blank">Abrir no Google Maps</a> ]
          {/* [ <a href="dangerouslySetInnerHTML:openStreetView(-4.718299,-37.545715);">Visualizar no Street View</a> ] */}
        </div>
      </Callout>
      <img src={markerTypes[type][ignition]} />
      {vehicle?.description?.length > 0 && <TitleContainer>{vehicle.description}</TitleContainer>}
    </Container>

    // <div style={{
    //   'display': 'flex',
    //   'flexDirection': 'column',
    //   'justifyContent': 'center',
    //   'alignItems': 'center',
    //   'cursor': 'pointer',
    // }}>
    // {/* // <Marker
    // //   // ref={mark => this.state.markers[index] = mark}
    // //   coordinate={{latitude: item.latitude, longitude: item.longitude}}
    // //   // image={this.state.markerIcon[0][item.ignicao]}
    // //   // rotation={item.rotacao}
    // //   title={item.veiculo.descricao}
    // //   anchor={{x: 0.5, y: 0.5}}
    // //   calloutAnchor={{x: 0.5, y: 0.4}}
    // //   tracksViewChanges={false}
    // // > */}
    //   <Callout item={item} isVisible={showCallout} zIndex={index} onClickToClose={() => setShowCallout(false)}/>
    //   <div style={{
    //     'display': 'flex',
    //     'flexDirection': 'column',
    //     'justifyContent': 'center',
    //     'alignItems': 'center',
    //     // 'zIndex': -1,
    //   }} onClick={() => setShowCallout(true)}>
    //     {
    //       item.ignicao === 0 ?
    //         <CarOff style={carStyle}/> :
    //         <CarOn style={carStyle}/>
    //       // 1: {
    //       //   0: <CarOcorrenciaOff fill='#f0f' width={50} height={46} />
    //       //   1: <CarOcorrenciaOn fill='#f0f' width={50} height={46} />
    //       // },
    //     }
    //     {/* {this.state.markerIcon[0][item.ignicao]} */}
    //     <div style={styles.nameVehicleContainer}>
    //       <span numberoflines={2} style={{textAlign: 'center', backgroundColor: '#444', borderRadius: 4, color: '#FFF', padding: 5, fontWeight: 'bold'}}>
    //         {item.veiculo.descricao}
    //       </span>
    //     </div>
    //   </div>

    //   {/* <Callout tooltip={true}>
    //     <div style={{
    //       width: Dimensions.get('window').width - 40, // height: 200,
    //       backgroundColor: 'white', marginBottom: 25, padding: 15, borderRadius: 15,
    //       borderColor: '#DDD', borderWidth: 1,
    //     }}>
    //       {
    //         !sos ? (
    //           <>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Veículo: </span><span style={styles.calloutText} numberOfLines={2}>{item.veiculo.descricao}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Data/hora: </span><span style={styles.calloutText}>{item.datetime.formatted}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Endereço: </span><span style={styles.calloutText} numberOfLines={2}>{item.endereco && item.endereco.split('<br />').join(' - ')}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Velocidade: </span><span style={styles.calloutText}>{item.velocidade.formatted}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Ignição: </span><span style={styles.calloutText}>{item.ignicao ? 'LIGADA' : 'DESLIGADA'}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Odômetro: </span><span style={styles.calloutText}>{item.odometro}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Horímetro: </span><span style={styles.calloutText}>{item.horimetro}</span>
    //           </div>
    //           </>
    //         ) : (
    //           <>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Cliente: </span><span style={styles.calloutText} numberOfLines={2}>{item.cliente.descricao}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Veículo: </span><span style={styles.calloutText} numberOfLines={2}>{item.veiculo.marca} {item.veiculo.modelo}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Cor: </span><span style={styles.calloutText} numberOfLines={2}>{item.veiculo.cor}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Placa: </span><span style={styles.calloutText} numberOfLines={2}>{item.veiculo.placa}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Data/hora: </span><span style={styles.calloutText}>{item.datetime.formatted}</span>
    //           </div>
    //           {/*
    //           <div style={styles.calloutTextContainer}>
    //             <span>Endereço: </span><span style={styles.calloutText} numberOfLines={2}>{item.endereco && item.endereco.split('<br />').join(' - ')}</span>
    //           </div>

    //           <div style={styles.calloutTextContainer}>
    //             <span>Velocidade: </span><span style={styles.calloutText}>{item.velocidade.formatted}</span>
    //           </div>
    //           <div style={styles.calloutTextContainer}>
    //             <span>Ignição: </span><span style={styles.calloutText}>{item.ignicao ? 'LIGADA' : 'DESLIGADA'}</span>
    //           </div>
    //           </>
    //         )
    //       }
    //     </div>
    //   </Callout>
    // </Marker> */}
    // </div>
  );
}
